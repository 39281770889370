import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { PriceSummary } from './price-summary';
import { Subtotal } from './subtotal';
import styles from './style.css';

interface IPricePreviewProps {
    embroiderer: EmbroidererProps;
}

export const PricePreview: React.FC<IPricePreviewProps> = ({ embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.pricePreview}>
            <div className={styles.priceSummaries}>
                <PriceSummary
                    text={t('Embroidery')}
                    price={embroiderer.embroideryPrice}
                    dataTest="custom-embroidery-summary"
                    isEmbroideryPrice
                />
                <PriceSummary
                    text={t('Product')}
                    price={embroiderer.productPrice}
                    dataTest="product-cost-summary"
                />
            </div>
            {' '}
            <div>
                <Subtotal price={embroiderer.subtotal} />
            </div>
        </div>
    );
};
