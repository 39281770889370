import React, { useState } from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { Checkbox, CheckboxEventHandler } from './checkbox-flags';
import { Flags } from './flags';
import { IEmbroideryParams } from '../embroidery';
import { FlagRoles } from '../flag-roles';

export const FlagsWithCheckbox: React.FC<IEmbroideryParams> = (
    {
        embroiderer,
        embroideryPage,
    },
) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange: CheckboxEventHandler = (event) => {
        embroideryPage.setCheckBoxValue(event.target.checked)();
        setIsChecked(event.target.checked);
        embroideryPage.clear();
        embroiderer.replaceView('flags', { subType: FlagRoles.flag1 });
    };
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </Section>
            <Flags
                embroiderer={embroiderer}
                embroideryPage={embroideryPage}
                type={FlagRoles.flag}
            />
        </OptionPage>
    );
};
