import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { LeftActionButton } from 'ui/component/embroidery-configurator/pages/start/configure/left-action-button';
import { AccountState } from '@silkpwa/module/account/account-interfaces';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface DownloadButtonProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ account, embroiderer }: DownloadButtonProps) => {
    const config: SilkRestappDataConfigInfoInterface = useConfig();
    const text = config.embroidery_downloads_button_title ?? 'Download';
    if (!(embroiderer.liquidPixel.enabled && config.embroidery_downloads_allowed && account.isLoggedIn)) return null;
    return (
        <LeftActionButton
            faIcon="fa-solid fa-download"
            text={text}
            action={embroiderer.download}
            validate={embroiderer.canDownload}
        />
    );
};
