import React from 'react';
import { Overlay } from './overlay';
import { SaveEcPopup } from './saveec/save-ec-popup';

const Ec = ({
    close,
    ec,
    embroiderer,
    text,
}) => {
    const ecElement = {
        POPUP: <SaveEcPopup close={close} embroiderer={embroiderer} text={text} />,
    };

    return ecElement[ec] || null;
};

export const ShowSaveEcPopup = ({
    close,
    ec,
    embroiderer,
    text,
}) => (
    <Overlay handleClick={close}>
        <Ec ec={ec} close={close} embroiderer={embroiderer} text={text} />
    </Overlay>
);
