import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const SaveButton = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();

    if (embroideryPage.canSave === undefined) return null;

    return embroideryPage.canSave && (
        <AccessibleButton
            tag="div"
            data-test="emb-text-save-btn"
            className={classes({
                [styles.nextButton]: true,
                [styles.save]: embroideryPage.canSave,
            })}
            isLocked={!embroideryPage.canSave}
            action={() => true}
            onClick={() => {
                embroideryPage.save();
                embroiderer.popView();
            }}
        >
            <span className={styles.title}>{t('Save')}</span>
        </AccessibleButton>
    );
};
