import React, { CSSProperties, useEffect } from 'react';
import { useValidatedInput } from '@silkpwa/module/hooks/use-validated-input';
import { RemoveButton } from 'ui/component/embroidery-configurator/components/remove-button';
import { TextInput } from 'ui/component/embroidery-configurator/components/text-input';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import { ErrorMessage } from 'ui/component/embroidery-configurator/components/error-message';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

import IRulePerLine = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

interface TextItemProps {
    value: string;
    placeholder: string;
    handleChange: (text: string) => void;
    handleAdd: () => void;
    dataTest: string;
    canRemove: boolean;
    handleRemove: (i: number) => void;
    handleClearLines: () => void;
    lineNumber: number;
    price: number;
    invalidTextMessage: string;
    validateText: (text: string) => void;
    rulePerLine: IRulePerLine;
    linesVisibility: boolean[];
    isEmbroideryLockdown: boolean;
    hint?: string | null;
    textStyle?: CSSProperties;
    isSectionInvalid?: boolean;
    textError?: boolean;
}

export const TextItem: React.FC<TextItemProps> = (
    {
        value,
        placeholder,
        handleChange,
        handleAdd,
        dataTest,
        canRemove,
        handleRemove,
        handleClearLines,
        lineNumber,
        price,
        invalidTextMessage,
        validateText,
        rulePerLine,
        linesVisibility,
        isEmbroideryLockdown,
        hint,
        textStyle,
        isSectionInvalid,
        textError,
    },
) => {
    const [freeValue, setFreeValue, isValid] = useValidatedInput({
        onChange: handleChange,
        value,
        isValid: validateText,
    });

    useEffect(() => {
        /**
         * Removes empty text line from state so that embroidery price is not calculated for empty line
         */
        if (lineNumber === 1 && !freeValue) {
            handleClearLines();
        }
    }, [freeValue, lineNumber]);

    const currentLineIndex: number = lineNumber - 1;
    const isVisible = !!linesVisibility[currentLineIndex];

    const canShowRemoveButton = (rulePerLine: IRulePerLine): boolean => {
        const canShow = isEmbroideryLockdown ? !rulePerLine.is_required : true;
        return canRemove && canShow;
    };

    return (
        <div className={styles.textItemWrap}>
            <div className={classes({
                [styles.hideTextItem]: !isVisible,
                [styles.rulePerLineLabel]: (rulePerLine && rulePerLine.label),
                [styles.textItem]: true,
            })}
            >
                <div className={styles.textItemRightPrice}>
                    <Price
                        dataTest="line-price"
                        className={classes({
                            [styles.price]: true,
                        })}
                        price={price}
                    />
                </div>
                <div className={styles.textItemInputWrapper}>
                    <TextInput
                        value={freeValue}
                        placeholder={placeholder}
                        handleChange={setFreeValue}
                        handleEnter={handleAdd}
                        dataTest={dataTest}
                        style={textStyle}
                        hasError={!isValid || textError}
                        rulePerLine={rulePerLine}
                        className={styles.textItemInput}
                        isEmbroideryLockdown={isEmbroideryLockdown}
                        isSectionInvalid={isSectionInvalid}
                    />
                    <div className={styles.textItemRightRemove}>
                        {canShowRemoveButton(rulePerLine) && (
                            <RemoveButton
                                dataTest={`remove-line-${lineNumber}`}
                                className={styles.remove}
                                handleRemove={handleRemove}
                            />
                        )}
                    </div>
                </div>
                {hint ? (
                    <span className={styles.textHint}>
                        (
                        {hint}
                        )
                    </span>
                ) : ''}
            </div>
            <ErrorMessage show={isVisible && !isValid} message={invalidTextMessage} />
        </div>
    );
};
