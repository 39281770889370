/**
 * Temporary workaround to combine the "saved" options with the "API" options during a
 * price reload.
 *
 * TODO: A better solution would be to refactor the configurator so options are split into
 * the "metadata" carrying what is "allowed" and the "data" carrying the user "selections".
 */
export function combineOptions(oldOptions, newOptions, isPriceReload: boolean) {
    // if the new options were not passed down due to a price reload, they
    // should replace the current options completely.
    if (!isPriceReload) {
        return newOptions;
    }

    // create map to lookup old options during combination
    const oldOptionsLookup: any = {};
    oldOptions.forEach((o) => {
        oldOptionsLookup[o.type] = o;
    });

    // combiner functions for each option type that take the user selections
    // from the old state and add overwrite the selections coming from API
    const combiners = {
        text: (oldOption, newOption) => ({
            ...newOption,
            lines: oldOption.lines,
            color: oldOption.color,
            font: oldOption.font,
            placement: oldOption.placement,
        }),
        logos: (oldOption, newOption) => ({
            ...newOption,
            logos: oldOption.logos,
        }),
        flags: (oldOption, newOption) => ({
            ...newOption,
            flag: oldOption.flag,
        }),
        'special-instructions': (oldOption, newOption) => ({
            ...newOption,
            text: oldOption.text,
        }),
    };

    return newOptions.map((o) => {
        const combiner = combiners[o.type];
        if (!combiner) return o;
        return combiner(oldOptionsLookup[o.type], o);
    });
}
