import { useState } from 'react';
import { LogoDetailResponseData } from 'ui/component/embroidery-configurator/api/embroidery-repository';

// Custom hook for handling search logic
const useSearch = (items: LogoDetailResponseData[]) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredItems = items.filter(
        item => (
            item.design_description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.logo_number.toLowerCase().includes(searchTerm.toLowerCase())
        ),
    );

    const handleSearchChange = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    };

    return { searchTerm, filteredItems, handleSearchChange };
};

export { useSearch };
