import React from 'react';
import { ImageSwatch } from 'ui/component/embroidery-configurator/components/color-swatches';
import { IEmbroideryPage, IFlagOptions } from 'ui/component/embroidery-configurator/pages/flags/embroidery';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { FlagRoles } from '../../../flags/flag-roles';

interface IFlagPreviewProps {
    embroideryPage: IEmbroideryPage;
    parentEmbroideryPage: any;
    embroiderer: EmbroidererProps;
}

export const FlagPreview = (
    {
        embroideryPage: {
            flag,
            flag1,
            flag2,
            flagOptions,
        },
        parentEmbroideryPage,
        embroiderer,
    }: IFlagPreviewProps,
) => {
    const bothFlag: string = flag;
    const leftFlag: string = flag1;
    const rightFlag: string = flag2;
    const t = usePhraseTranslater();

    const findFlagDetail = (flagValue: string): IFlagOptions | undefined => flagOptions.find(
        item => item.backendValue === flagValue,
    );

    const bothFlagDetail = findFlagDetail(bothFlag);
    const leftFlagDetail = findFlagDetail(leftFlag);
    const rightFlagDetail = findFlagDetail(rightFlag);

    const currentPageData = extractState(embroiderer, 'flags');
    const action = {
        handleAction: (type: string) => {
            parentEmbroideryPage.openSubOption(currentPageData, { subType: type });
        },
    };

    if (!bothFlagDetail && !leftFlagDetail && !rightFlagDetail) return null;

    const renderFlag = (title, imageSrc, type = 'flags') => (
        <div className={styles.flag}>
            <div className={styles.title}>{title}</div>
            <div className={styles.image}>
                <ImageSwatch src={imageSrc} />
            </div>
            <AccessibleButton
                tag="div"
                className={classes({ [styles.actionButton]: true })}
                action={() => action.handleAction(type)}
            >
                <i className="fas fa-pencil-alt" />
            </AccessibleButton>
        </div>
    );

    return (
        <div className={styles.flagContentWrapper}>
            <div className={styles.flagContent}>
                {bothFlag && renderFlag(t('Left and Right Collar'), bothFlagDetail.frontendValue)}
                {!bothFlag && renderFlag(t('Left Collar'), leftFlagDetail.frontendValue, FlagRoles.flag1)}
                {!bothFlag && renderFlag(t('Right Collar'), rightFlagDetail.frontendValue, FlagRoles.flag2)}
            </div>
        </div>
    );
};
