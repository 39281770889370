import { types } from 'ui/component/embroidery-configurator/pages/types';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { IEmbroideryOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';

interface ISectionErrorProps {
    isSaveFailed: boolean;
    option: any;
    embroiderer: EmbroidererProps;
}

const notRequiredOptions: string[] = [
    'special-instructions',
];

const shouldNotBeValidated = (optionType?: string) => !optionType || notRequiredOptions.includes(optionType);

const isValidOption = (
    option: IEmbroideryOption,
    embroiderer: EmbroidererProps,
) => types[option.type].isValid(option, embroiderer);

export const SectionError = (
    {
        isSaveFailed,
        option,
        embroiderer,
    }: ISectionErrorProps,
): boolean => {
    const { isEmbroideryLockdown } = embroiderer;
    const { requiredRuleLines, type } = option;
    const hasRequiredLines = Boolean(requiredRuleLines && requiredRuleLines.length);
    const shouldSkipTextValidation = type === 'text' && !hasRequiredLines;

    if (!isEmbroideryLockdown || !isSaveFailed || shouldNotBeValidated(type) || shouldSkipTextValidation) {
        return false;
    }

    return !isValidOption(option, embroiderer);
};
