import { useState, useEffect, RefObject } from 'react';

/**
 * detects when a (CSS) sticky element changes "sticky" state
 * @param {object} ref react ref. if not provided, a new one will be used instead.
 * @param {object} observerSettings Observer's settings object
 */
const useDetectSticky = (ref: RefObject<HTMLDivElement>, observerSettings = {
    rootMargin: '-1px 0px 0px 0px',
    threshold: [1],
}) => {
    const [isSticky, setIsSticky] = useState(false);

    // mount
    useEffect(() => {
        const cachedRef = ref.current;
        const observer = new IntersectionObserver(
            ([e]) => {
                setIsSticky(e.intersectionRatio < 1);
            },
            observerSettings,
        );
        observer.observe(cachedRef);
        // unmount
        return () => {
            observer.unobserve(cachedRef);
        };
    }, []);
    return [isSticky, ref, setIsSticky];
};

export { useDetectSticky };
