import React from 'react';
import { extractState, nextPage } from '../util';

export class State extends React.Component<any, any> {
    constructor(props) {
        super(props);

        const { embroiderer, data } = this.props;
        if (data.sku) {
            const [firstOption] = embroiderer.options.filter(
                (x: { type: string; sku: string }) => x.type === 'print-screen' && x.sku === data.sku,
            );
            this.state = firstOption;
        } else {
            this.state = extractState(embroiderer, 'print-screen');
        }
    }

    render() {
        const { children, embroiderer } = this.props;

        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                nextPage: nextPage(embroiderer, 'print-screen'),
            },
        });
    }
}
