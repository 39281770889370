import React, { ChangeEventHandler } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export type CheckboxEventHandler = ChangeEventHandler<HTMLInputElement>;

interface ICheckbox {
    checked: boolean;
    onChange: CheckboxEventHandler;
}

export const Checkbox: React.FC<ICheckbox> = ({ checked, onChange }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.checkboxWrapper}>
            <label htmlFor="checkbox">
                {t('I Want 2 Different Flags')}
                <input
                    id="checkbox"
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
            </label>
        </div>
    );
};
export default Checkbox;
