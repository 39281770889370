import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface INextButtonProps {
    onClick: () => void;
}

export const NextButton: React.FC<INextButtonProps> = ({ onClick }) => {
    const t = usePhraseTranslater();
    return (
        <AccessibleButton
            tag="div"
            data-test="emb-logo-next-btn"
            className={classes({
                [styles.nextButton]: true,
                [styles.save]: true,
            })}
            action={() => true}
            onClick={onClick}
        >
            <span className={styles.title}>{t('Next')}</span>
        </AccessibleButton>
    );
};
