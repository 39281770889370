import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const PageBody = ({ children }) => (
    <div className={classes(styles.body, styles.bodyScrollDelayed)}>
        <div className={styles.bodyContent}>
            {children}
        </div>
    </div>
);
