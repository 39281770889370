import React from 'react';
import { ColorSwatch } from 'ui/component/embroidery-configurator/components/color-swatches';
import { StylePreview } from './style-preview';
import styles from './style.css';

export const ColorStylePreview = ({ embroideryPage, handleAction }) => {
    if (!embroideryPage.colorValue) return null;
    const border = { border: 0 };
    return (
        <StylePreview title="Thread Color" handleAction={handleAction}>
            <div className={styles.colorContentWrapper}>
                <div className={styles.colorContent}>
                    <ColorSwatch
                        color={embroideryPage.colorValue}
                        border={border}
                    />
                </div>
            </div>
        </StylePreview>
    );
};
