import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { ITextOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';

import EmbLineRule = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

const isValidText = (
    index: number,
    isLockdown: boolean,
    hasRequiredLines: boolean,
    linesVisibility?: boolean[],
    requiredRules?: EmbLineRule[],
    text?: string,
): boolean => {
    const isNotVisible = Boolean(linesVisibility && !linesVisibility[index]);
    if (isNotVisible) {
        return true;
    }

    const textIsNotEmpty = Boolean(text && text.length);
    const isLineRequired = Boolean(
        requiredRules &&
        requiredRules.length &&
        requiredRules[index] &&
        requiredRules[index].is_required,
    );

    const isValidInLockdown = (): boolean => {
        const checkRequiredOnly = !isLineRequired || textIsNotEmpty;
        return hasRequiredLines ? checkRequiredOnly : textIsNotEmpty;
    };

    return isLockdown ? isValidInLockdown() : textIsNotEmpty;
};

export const isValidTextSection = (
    state: ITextOption,
    embroiderer: EmbroidererProps | undefined = undefined,
): boolean => {
    const {
        lines,
        linesVisibility,
        visibleLinesCount,
        requiredRuleLines,
        minLines,
        maxLines,
    } = state;
    const linesLength: number = Object.keys(lines).length;
    const isLockdown = Boolean(embroiderer && embroiderer.isEmbroideryLockdown);
    const hasRequiredLines = Boolean(requiredRuleLines && requiredRuleLines.length);

    const validCount: boolean = visibleLinesCount >= minLines && visibleLinesCount <= maxLines;

    const validLines: string[] = lines.filter(
        (
            text: undefined | string,
            i: number,
        ) => isValidText(i, isLockdown, hasRequiredLines, linesVisibility, requiredRuleLines, text),
    );
    const validLinesCount: number = Object.keys(validLines).length;

    const visibleValidLines: string[] = lines.filter(
        (
            text: undefined | string,
            i: number,
        ) => linesVisibility && linesVisibility[i] && Boolean(text && text.length),
    );

    const validText: boolean = !isLockdown || !hasRequiredLines
        ? Boolean(visibleValidLines && visibleValidLines.length)
        : validLinesCount === linesLength;

    return validCount && validText;
};

/*
    isValid is has two options
    1. for lockdown user evey thing should be validated and required
    2. for non lockdown user
        A. if text field is empty
            - user can add to cart without selecting color, font, alignment
        B. if text field is not empty
            - user can not add to cart without selecting color, font, alignment
 */
export const isValid = (
    state: ITextOption,
    embroiderer: EmbroidererProps | undefined = undefined,
    byPassEmptyTextLine: boolean | undefined = true,
): boolean => {
    const { font, color, placement } = state;

    const validTextSection: boolean = isValidTextSection(state, embroiderer);
    const validStyleSection: boolean = font.length > 0 && color.length > 0 && placement.length > 0;

    const isValidMainResult: boolean = validTextSection && validStyleSection;

    if (byPassEmptyTextLine !== undefined && byPassEmptyTextLine) {
        const isLockdown = Boolean(embroiderer && embroiderer.isEmbroideryLockdown);
        return isValidMainResult || (!isLockdown && !validTextSection && !validStyleSection);
    }

    return isValidMainResult;
};

export const isValidColorSection = (state: ITextOption): boolean => state.color.length > 0;
export const isValidFontSection = (state: ITextOption): boolean => state.font.length > 0;
export const isValidPlacementSection = (state: ITextOption): boolean => state.placement.length > 0;
