import React from 'react';
import { TooltipMessage } from 'ui/component/embroidery-configurator/components/tooltip-message';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Divider } from 'ui/component/embroidery-configurator/components/divider';
import styles from './style.css';

export const Constraints = () => {
    const t = usePhraseTranslater();
    let key = 0;
    const constraintItems = [
        {
            type: 'format',
            label: t('file type'),
            detail: t('jpg, png, tiff, eps, dst'),
        },
        {
            type: 'dimension',
            label: t('minimum dimensions'),
            detail: t('1000px  X  1000px'),
        },
        {
            type: 'size',
            label: t('maximum file size'),
            detail: t('25mb'),
        },
    ];

    return (
        <TooltipMessage className={styles.message} overlayTitle={t('Logo Requirements')} overlay>
            <div className={styles.content}>
                <div className={styles.itemList}>
                    {constraintItems.map((item, index) => {
                        key += 1;
                        return (
                            <React.Fragment key={key}>
                                <div key={item.type} className={styles.item}>
                                    <div className={styles.label}>{item.label}</div>
                                    <div className={styles.detail}>{item.detail}</div>
                                </div>
                                {index !== constraintItems.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </TooltipMessage>
    );
};
