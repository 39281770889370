export const isValidFlag1 = state => (state.flag1 && state.flag1.length > 0) &&
    (state.flag1Position && state.flag1Position.length > 0);

export const isValidFlag2 = state => (state.flag2 && state.flag2.length > 0) &&
    (state.flag2Position && state.flag2Position.length > 0);

export const isValid = (state) => {
    if (state.flag && state.flag.length > 0) {
        return true;
    }
    return isValidFlag1(state) && isValidFlag2(state);
};
