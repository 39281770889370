const EmbTextLightBackgroundColor = '#F8F6F6';
const EmbTextDarkBackgroundColor = '#d3d3d3';

function getRGB(c) {
    return parseInt(c, 16) || c;
}

function getsRGB(c) {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : ((getRGB(c) / 255 + 0.055) / 1.055) ** 2.4;
}

function getLuminance(hexColor) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    );
}

function getContrast(f, b) {
    const L1 = getLuminance(f);
    const L2 = getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

/**
 * get the background color based on selected text color
 * @param fontColor hex color code #fffff
 */
export const dynamicColor = (fontColor: string) => {
    const whiteContrast = getContrast(fontColor, EmbTextLightBackgroundColor);
    const blackContrast = getContrast(fontColor, EmbTextDarkBackgroundColor);

    return whiteContrast > blackContrast ? EmbTextLightBackgroundColor : EmbTextDarkBackgroundColor;
};
