export enum SubOptionsType { onFile = 'onFile', upload = 'upload', placement = 'placement' }

export interface ISubOptions {
    subType: string;
    pageNo: number;
    label: string;
    nextSubOption: ISubOptions | null;
}

export const subOptions = (
    t: any,
    type: 'onFile' | 'upload' | 'placement',
): ISubOptions => {
    let subType: string;
    let pageNo: number;
    let label: string;
    let nextSubOption: ISubOptions | null;
    switch (type) {
        case SubOptionsType.onFile:
            subType = 'onFile';
            pageNo = 1;
            label = t('On File');
            nextSubOption = subOptions(t, SubOptionsType.placement);
            break;
        case SubOptionsType.upload:
            subType = 'upload';
            pageNo = 1;
            label = t('upload');
            nextSubOption = subOptions(t, SubOptionsType.placement);
            break;
        case SubOptionsType.placement:
            subType = 'placement';
            pageNo = 2;
            label = t('alignment');
            nextSubOption = null;
            break;
        default:

            break;
    }
    const subOption: ISubOptions = {
        subType,
        pageNo,
        label,
        nextSubOption,
    };

    return subOption;
};
