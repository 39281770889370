import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IPriceProps {
    price: number;
    className?: string;
    style?: any;
    noPlus?: boolean;
    dataTest?: string;
}

export const Price: React.FC<IPriceProps> = ({
    dataTest,
    className,
    style,
    noPlus,
    price,
}) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return (
        <div data-test={dataTest} className={classes(styles.price, className, 'weglot-exclude')} style={style}>
            {noPlus ? '' : '+ '}
            {formatPrice(price)}
        </div>
    );
};
