import React from 'react';
import { createPortal } from 'react-dom';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    LogoSignInPopupBox,
} from 'ui/component/embroidery-configurator/pages/logos/configure/logo/logo-on-file/logo-sign-in-popup-form';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { LeftActionButton } from 'ui/component/embroidery-configurator/pages/start/configure/left-action-button';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface DownloadSignInBoxProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
}

export const DownloadSignInBox: React.FC<DownloadSignInBoxProps> = ({
    account,
    embroiderer,
}: DownloadSignInBoxProps) => {
    const t = usePhraseTranslater();
    const config: SilkRestappDataConfigInfoInterface = useConfig();
    if (!(embroiderer.liquidPixel.enabled && config.embroidery_downloads_allowed && !account.isLoggedIn)) return null;

    return (
        <ModalSystemConsumer>
            {({ open, setOpen }: {open: boolean; setOpen: (v: boolean) => void}) => (
                <>
                    <LeftActionButton
                        faIcon="fa-solid fa-download"
                        text={t('Log In to ') + config.embroidery_downloads_button_title}
                        action={() => setOpen(true)}
                        validate
                    />
                    {open && createPortal(
                        <LogoSignInPopupBox
                            handleClose={() => setOpen(false)}
                        />,
                        getEmbeddedEmbConfiguratorElement(),
                    )}
                </>
            )}
        </ModalSystemConsumer>
    );
};
