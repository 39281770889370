import React from 'react';
import { createPortal } from 'react-dom';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { OnClickOrScroll } from '@silkpwa/module/react-component/on-click-or-scroll';
import { Overlay } from 'ui/component/overlay';
import { classes } from '@silkpwa/module/util/classes';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';
import styles from './style.css';

interface ITooltipMessageProps {
    className: string;
    overlayTitle: string;
    overlay: boolean;
    children: React.ReactNode;
}

export const TooltipMessage: React.FC<ITooltipMessageProps> = ({
    className,
    overlayTitle = '',
    overlay,
    children,
}) => (
    <UseState initialState={false}>
        {([open, setOpen]) => (
            overlay ? (
                <>
                    <span className={styles.tooltipIcon}>
                        <AccessibleButton
                            tag="span"
                            action={() => setOpen(!open)}
                        >
                            <i className="fa-solid fa-circle-info" />
                        </AccessibleButton>
                    </span>
                    {
                        createPortal(
                            <Overlay
                                active={open}
                                close={() => setOpen(false)}
                                className={styles.overlayWrapper}
                            >
                                <>
                                    {overlayTitle && (
                                        <div className={styles.overlayHeader}>
                                            <div className={styles.overlayTitle}>
                                                {overlayTitle}
                                            </div>
                                            <div className={styles.overlayAction}>
                                                <AccessibleButton
                                                    tag="span"
                                                    action={() => setOpen(!open)}
                                                >
                                                    <i className="fa-solid fa-xmark" />
                                                </AccessibleButton>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.overlayContent}>
                                        {children}
                                    </div>
                                </>
                            </Overlay>,
                            getEmbeddedEmbConfiguratorElement(),
                        )
                    }
                </>
            ) : (
                <OnClickOrScroll handleEvent={() => setOpen(false)}>
                    {setElm => (
                        <span className={styles.tooltipIcon} ref={setElm}>
                            <AccessibleButton
                                tag="span"
                                action={() => setOpen(!open)}
                            >
                                <i className="fa-solid fa-circle-info" />
                            </AccessibleButton>
                            <div className={classes({
                                [styles.popupMessage]: true,
                                [className]: true,
                                [styles.popupMessageOpened]: open,
                            })}
                            >
                                {children}
                            </div>
                        </span>
                    )}
                </OnClickOrScroll>
            )
        )}
    </UseState>
);
