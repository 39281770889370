import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const print = (args, match, number) => {
    if (typeof args[number] !== 'undefined') {
        return args[number];
    }
    return match;
};

const sprintf = (format, args) => format.replace(/{(\d+)}/g, (match, number) => print(args, match, number));
export const ErrorMessage = ({ embroideryPage, viewData }) => {
    const t = usePhraseTranslater();
    const style: string[] = [];
    const step: number[] = [];

    if (viewData.subType === 'placement' && embroideryPage.isValidPlacementSection) {
        if (!embroideryPage.isValidColorSection) {
            style.push('color');
            step.push(1);
        }
        if (!embroideryPage.isValidFontSection) {
            style.push('font');
            step.push(2);
        }
    } else if (viewData.subType === 'font' && embroideryPage.isValidFontSection) {
        if (!embroideryPage.isValidColorSection) {
            style.push('color');
            step.push(1);
        }
    }

    const error = style.length ? '*Please choose {0} in step {1} to continue.' : '';

    const errorMessage = sprintf(error, [style.join(', '), step.join(', ')]);

    return (
        errorMessage && (
            <div className={styles.error}>
                {t(errorMessage)}
            </div>
        )
    );
};
