import React from 'react';
import styles from './style.css';

interface ISectionTitleProps {
    children: React.ReactNode;
}

export const SectionTitle: React.FC<ISectionTitleProps> = ({ children }) => (
    <p className={styles.sectionTitle}>
        <span>{children}</span>
    </p>
);
