import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface IRightActionsProps {
    isFullScreen: boolean;
    setIsFullScreen: (value: boolean) => void;
}

export const RightActions: React.FC<IRightActionsProps> = ({ isFullScreen, setIsFullScreen }) => (
    <div className={
        classes({
            [styles.rightActions]: true,
        })}
    >
        <AccessibleButton
            tag="div"
            action={() => setIsFullScreen(!isFullScreen)}
            className={styles.resize}
        >
            {isFullScreen ? (
                <i className="fa-solid fa-down-left-and-up-right-to-center" />
            ) : (
                <i className="fa-solid fa-up-down-left-right" />
            )}
        </AccessibleButton>
    </div>
);
