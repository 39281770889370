import React from 'react';
import { StylePreview } from './style-preview';
import styles from './style.css';

const FontSwatch = ({ font, label }) => (
    <span
        style={{
            fontFamily: `'${font}'`,
        }}
    >
        {label}
    </span>
);

export const FontStylePreview = ({ embroideryPage, handleAction }) => {
    if (!embroideryPage.font) return null;
    return (
        <StylePreview title="Font Face" handleAction={handleAction}>
            <div className={styles.fontContentWrapper}>
                <div className={styles.fontContent}>
                    <FontSwatch font={embroideryPage.font} label={embroideryPage.font} />
                </div>
            </div>
        </StylePreview>
    );
};
