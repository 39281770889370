import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const Configure = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <SectionTitle>{t('Alteration And Piping')}</SectionTitle>
            </Section>
        </OptionPage>
    );
};
