interface SimpleProductAttribute {
    code: string;
    value: any;
}

interface SimpleProductObj {
    attributes: Array<SimpleProductAttribute>;
}

export const getLiquidPixelSku = (simpleProduct: SimpleProductObj) => {
    if (typeof simpleProduct.attributes === 'undefined') {
        return null;
    }

    const liquidPixelSku = simpleProduct.attributes.filter(x => x.code.indexOf('liquidpixel_sku') === 0);
    if (typeof liquidPixelSku === 'undefined') {
        return null;
    }

    return liquidPixelSku.map(x => x.value)[0];
};
