import React from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { ColorSwatches, ColorSwatch } from 'ui/component/embroidery-configurator/components/color-swatches';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { filterColors } from './filter-colors';
import { colorPreview } from './color-preview';
import styles from './style.css';

export const ColorSection = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <SectionTitle>
                {t('Color')}
            </SectionTitle>
            <Section>
                <ColorSwatches
                    items={filterColors(embroiderer, embroideryPage)}
                    isSelected={c => c.backendValue === embroideryPage.color}
                    keySelector={c => c.backendValue}
                    dataTestFun={item => `color-${item.backendValue}`}
                    renderSwatch={item => (
                        <ColorSwatch
                            color={item.frontendValue}
                            border={item.border}
                        />
                    )}
                    handleChangeSelector={c => embroideryPage.setColor(c.backendValue)}
                />
                <div className={styles.colorPreview}>
                    {colorPreview(embroideryPage)}
                </div>
            </Section>
        </>
    );
};
