import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { IEmbroideryOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';

/**
 * SubConfigure is array of components, used to implement sub pages for the respective types
 * e.g. pages > logos have a subpage login
 */
interface SubConfigureMap {
    [type: string]: React.ComponentType<any>;
}

export interface TypeImpl {
    Configure: React.ComponentType<any>;
    SubConfigure?: SubConfigureMap;
    State: React.ComponentType<any>;
    clear?: <T, >(state: T) => T;
    layers?: <T, >(state: T, face: string) => string[];
    isValid?: (state: IEmbroideryOption, embroiderer?: EmbroidererProps, byPassEmptyTextLine?: boolean) => boolean;
    price?: <T, U>(state: T, embroideryState: U, quantity: number) => number;
}

interface TypeMap {
    [type: string]: TypeImpl;
}

export const types: TypeMap = {};
