import React from 'react';
import styles from './style.css';

interface IDescriptionProps {
    content: string;
}

export const Description: React.FC<IDescriptionProps> = ({ content }) => {
    if (!content) return null;

    return (
        <div className={styles.flagDescription}>
            {content}
        </div>
    );
};
