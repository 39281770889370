import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const Configure = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <SectionTitle>{t('Instructions')}</SectionTitle>
                <textarea
                    data-test="special-instructions"
                    className={styles.instructions}
                    onChange={ev => embroideryPage.setText(ev.target.value)}
                    value={embroideryPage.text}
                />
            </Section>
        </OptionPage>
    );
};
