import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import outline from 'ui/styles/outline-effect.css';
import { classes } from '@silkpwa/module/util/classes';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { Page } from '../page';
import { Preview } from '../preview';
import { AddToCartSummary } from '../add-to-cart-summary';
import { EmbroidererProps } from '../../add-embroidery-state';
import styles from './style.css';

interface IOptionPageProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: add more correct typing
    children: React.ReactNode;
}

const OptionPage: React.FC<IOptionPageProps> = ({
    children,
    account,
    embroiderer,
    embroideryPage,
}) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1041px)' });
    const dataTest = isDesktop ? 'embroidery-option-desktop' : 'embroidery-option-mobile';
    const wrapperClass = isDesktop ? styles.optionPageInnerWrapper : '';

    const pageRef = useRef<HTMLDivElement>(null);
    const scrollToElement = () => {
        // eslint-disable-next-line no-unused-expressions
        pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToElement();
    }, []);

    return (
        <div className={styles.optionPage} ref={pageRef}>
            <div className={wrapperClass} data-test={dataTest}>
                <Preview
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                    account={account}
                />
                <div className={classes({
                    [outline.shadow]: isDesktop,
                    [outline.left]: isDesktop,
                })}
                >
                    <Page
                        embroiderer={embroiderer}
                        embroideryPage={embroideryPage}
                    >
                        {children}
                    </Page>
                </div>
            </div>
            <AddToCartSummary embroiderer={embroiderer} />
        </div>
    );
};

const ConnectedOptionPage = connectAccount(OptionPage);

export { ConnectedOptionPage as OptionPage };
