import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { IImageInfo } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { getViewData } from 'ui/component/embroidery-configurator/pages/util';
import { LogoPreview } from 'ui/component/embroidery-configurator/components/logo-preview';
import { ConfirmationType } from '../confirmation-type';
import styles from './style.css';

type ConfirmationTypes = ConfirmationType.YesNo;

export interface IConfirmationData {
    logoNumber: number;
    image: IImageInfo;
}

export interface IConfirmation {
    title: string;
    confirmationType: ConfirmationTypes;
    data: IConfirmationData;
}

interface IConfigureProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any;
}

export const Configure: React.FC<IConfigureProps> = ({ embroiderer, embroideryPage }) => {
    const t = usePhraseTranslater();
    const viewData: IConfirmation = getViewData(embroiderer);

    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <div className={styles.confirmationSection}>
                    <div className={styles.content}>
                        <LogoPreview image={viewData.data.image} />
                    </div>
                    <div className={styles.title}>
                        {viewData.title ?? ''}
                    </div>
                    <div className={styles.actions}>
                        {viewData.confirmationType === 'YesNo' && (
                            <>
                                <AccessibleButton
                                    tag="div"
                                    data-test="yes-confirmation-ec"
                                    action={() => embroideryPage.confirmedYes(viewData.data)}
                                    className={classes(styles.actionButton, styles.actionSecondary)}
                                >
                                    {t('Yes')}
                                </AccessibleButton>
                                <AccessibleButton
                                    tag="div"
                                    data-test="no-confirmation-ec"
                                    action={embroideryPage.confirmedNo}
                                    className={classes(styles.actionButton, styles.actionPrimary)}
                                >
                                    {t('No')}
                                </AccessibleButton>
                            </>
                        )}
                    </div>
                </div>
            </Section>
        </OptionPage>
    );
};
