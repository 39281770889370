import React, { ReactEventHandler } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { LogoDetailResponseData } from '../../../../../api/embroidery-repository';
import style from './style.css';

interface ILogoListItemProps {
    itemIndex: number;
    logo: LogoDetailResponseData;
    currentlySelected: boolean;
    logoBaseUrl: string;
    handleClick: ReactEventHandler;
}

export const LogoListItem: React.FC<ILogoListItemProps> = ({
    itemIndex,
    logo,
    currentlySelected,
    logoBaseUrl,
    handleClick,
}) => (
    <button
        type="button"
        className={currentlySelected
                   ? classes(style.selectImage, style.selected)
                   : style.selectImage}
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={itemIndex}
        data-test={`logo-${logo.logo_number}`}
    >
        <img src={logoBaseUrl + logo.customer_id + logo.logo_path} alt={logo.design_description} />
    </button>
);
