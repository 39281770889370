import React, { CSSProperties } from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { AddItem } from 'ui/component/embroidery-configurator/components/add-item';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { fontFamily } from 'ui/component/embroidery-configurator/pages/text/font-family';
import { colorValue } from 'ui/component/embroidery-configurator/pages/text/color-value';
import { TextItem } from './text-item';
import { dynamicColor } from './dynamic-color';
import styles from './style.css';

import EmbLineRule = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

interface TextSectionProps {
    embroideryPage: any; // TODO: Same with  logo-on-file.tsx, will add in the future
    embroiderer: EmbroidererProps;
    isSectionInvalid?: boolean;
    textError?: boolean;
    setTextError?: (textError: boolean) => void;
    isShowTitle?: boolean;
}

export const TextSection = (
    {
        embroideryPage,
        embroiderer,
        isSectionInvalid,
        textError,
        setTextError,
        isShowTitle = true,
    }: TextSectionProps,
) => {
    const t = usePhraseTranslater();
    let key = 0;
    const getTextStyle = (): CSSProperties => {
        const defaultColor = '#333';
        if (embroideryPage.canSave) {
            const color = colorValue(embroideryPage) || defaultColor;
            const backgroundColor = dynamicColor(color);
            return {
                fontFamily: fontFamily(embroideryPage),
                color,
                backgroundColor,
            };
        }
        return { color: defaultColor };
    };

    return (
        <>
            {isShowTitle && <SectionTitle>{t('Text')}</SectionTitle>}
            <Section className={styles.sectionFullWidth}>
                {embroideryPage.embTextLines.map((rulePerLine: EmbLineRule, i: number) => {
                    const invalidTextMessage = embroiderer.messages.invalid_text.replace(
                        '{max_char}',
                        rulePerLine && rulePerLine.character_limit > 0
                            ? String(rulePerLine.character_limit) : embroideryPage.maxCharacters[i],
                    );
                    key += 1;
                    return (
                        <React.Fragment key={key}>
                            <TextItem
                                placeholder={t('Enter Your Text %1', rulePerLine.line_number)}
                                dataTest={`text-embroidery-line-${rulePerLine.line_number}`}
                                lineNumber={rulePerLine.line_number}
                                value={embroideryPage.lines[i] ?? ''}
                                price={(embroideryPage.priceForLine[i] * embroiderer.quantity)}
                                canRemove={embroideryPage.canRemoveLine}
                                handleAdd={embroideryPage.addLine}
                                handleChange={
                                    text => embroideryPage.setLine(
                                        i,
                                        text,
                                        Boolean(embroiderer.liquidPixel.enabled),
                                    )
                                }
                                handleRemove={embroideryPage.removeLine(i, setTextError)}
                                handleClearLines={embroideryPage.clearLines}
                                validateText={text => embroideryPage.validateText(i, text)}
                                invalidTextMessage={invalidTextMessage}
                                hint={embroiderer.textEmbroideryHints[i]
                                    ? embroiderer.textEmbroideryHints[i] : null}
                                rulePerLine={rulePerLine}
                                textStyle={getTextStyle()}
                                isSectionInvalid={isSectionInvalid}
                                textError={textError}
                                linesVisibility={embroideryPage.linesVisibility}
                                isEmbroideryLockdown={embroiderer.isEmbroideryLockdown}
                            />
                        </React.Fragment>
                    );
                })}
                {embroideryPage.canAddLine && (
                    <AddItem
                        dataTest="add-line"
                        handleClick={embroideryPage.addLine}
                    >
                        {t('Add Line')}
                    </AddItem>
                )}
            </Section>
        </>
    );
};
