import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { LogoActions } from '../configure/logo/logo-actions';

export const Login = ({ embroiderer, embroideryPage }) => {
    const t = usePhraseTranslater();
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <div>{t('Test Login')}</div>
            {embroideryPage.logos.map((logo, i) => (
                <LogoActions
                    logoNum={i}
                    logo={logo}
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                />
            ))}
        </OptionPage>
    );
};
