import React from 'react';
import { Preview } from 'ui/component/embroidery-configurator/components/preview';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import outline from 'ui/styles/outline-effect.css';
import { classes } from '@silkpwa/module/util/classes';
import { AddToCartSummary } from 'ui/component/embroidery-configurator/components/add-to-cart-summary';
import { Sections } from './sections';
import styles from './style.css';

interface ConfigureProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: Gave up on this one, will go back to add more correct typing
}

const Configure: React.FC<ConfigureProps> = ({
    account,
    embroiderer,
    embroideryPage,
}) => (
    <div>
        <div className={styles.configure}>
            <Preview
                embroiderer={embroiderer}
                embroideryPage={embroideryPage}
                account={account}
            />
            <div
                data-test="embroidery-options"
                className={classes({
                    [styles.embroideryOptions]: true,
                    [outline.shadow]: true,
                    [outline.left]: true,
                })}
            >
                <Sections
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                />
            </div>
        </div>
        <AddToCartSummary embroiderer={embroiderer} />
    </div>
);

const ConnectedConfigure = connectAccount(Configure);

export { ConnectedConfigure as Configure };
