export enum SubOptionsType { color = 'color', font = 'font', placement = 'placement'}

export interface ISubOptions {
    subType: string;
    pageNo: number;
    label: string;
    nextSubOption: ISubOptions | null;
}

export const subOptions = (
    t: any,
    type: 'color' | 'font' | 'placement',
): ISubOptions => {
    let subType: string;
    let pageNo: number;
    let label: string;
    let nextSubOption: ISubOptions | null;
    switch (type) {
        case SubOptionsType.color:
            subType = 'color';
            pageNo = 1;
            label = t('color');
            nextSubOption = subOptions(t, SubOptionsType.font);
            break;
        case SubOptionsType.font:
            subType = 'font';
            pageNo = 2;
            label = t('font');
            nextSubOption = subOptions(t, SubOptionsType.placement);
            break;
        case SubOptionsType.placement:
            subType = 'placement';
            pageNo = 3;
            label = t('alignment');
            nextSubOption = null;
            break;
        default:

            break;
    }
    const subOption: ISubOptions = {
        subType,
        pageNo,
        label,
        nextSubOption,
    };

    return subOption;
};
