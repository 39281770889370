import React from 'react';
import { StylePreview } from './style-preview';
import styles from './style.css';

const PlacementSwatch = ({ placement }) => (
    <span
        style={{
            fontSize: '1.4rem',
        }}
    >
        {placement}
    </span>
);

export const PlacementStylePreview = ({ embroideryPage, handleAction }) => {
    if (!embroideryPage.placement) return null;
    return (
        <StylePreview title="Placement" handleAction={handleAction}>
            <div className={styles.placementContentWrapper}>
                <div className={styles.placementContent}>
                    <PlacementSwatch placement={embroideryPage.placement} />
                </div>
            </div>
        </StylePreview>
    );
};
