import React, { useState } from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { TextSection } from 'ui/component/embroidery-configurator/pages/text/configure/text-section';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { IEmbroideryOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SubDivider } from 'ui/component/embroidery-configurator/components/divider';
import { ColorStylePreview } from '../text-style-preview';
import { FontStylePreview } from '../text-style-preview/font-style-preview';
import { PlacementStylePreview } from '../text-style-preview/placement-style-preview';
import styles from './style.css';


interface ITextContainerProps {
    embroiderer: any;
    parentEmbroideryPage: any;
    option: IEmbroideryOption;
    isSectionInvalid?: boolean;
    isSaveFailed?: boolean;
}

export const TextContainer = (
    {
        embroiderer,
        parentEmbroideryPage,
        option,
        isSectionInvalid,
        isSaveFailed,
    }: ITextContainerProps,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const currentPageData = extractState(embroiderer, 'text');
    const TextComponents = types.text;
    const t = usePhraseTranslater();
    const [textError, setTextError] = useState(false);
    const action = {
        validate: (embroideryPage): boolean => {
            if (!embroideryPage.isValidTextSection) {
                setTextError(true);
                return false;
            }
            setTextError(false);
            return true;
        },
        colorAction: (embroideryPage) => {
            if (action.validate(embroideryPage)) {
                parentEmbroideryPage.openSubOption(currentPageData, { subType: 'color' });
            }
        },
        fontAction: (embroideryPage) => {
            if (action.validate(embroideryPage)) {
                parentEmbroideryPage.openSubOption(currentPageData, { subType: 'font' });
            }
        },
        placementAction: (embroideryPage) => {
            if (action.validate(embroideryPage)) {
                parentEmbroideryPage.openSubOption(currentPageData, { subType: 'placement' });
            }
        },
        removeStyleAction: (embroideryPage) => {
            embroideryPage.clearStyles();
        },
        isValidStyle: embroideryPage => isSaveFailed && action.validate(embroideryPage),
    };
    return (
        <div className={styles.textContainer}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <TextComponents.State embroiderer={embroiderer} option={option}>
                {({ embroideryPage }) => (
                    <>
                        <div className={classes({
                            [styles.textContainerInner]: true,
                        })}
                        >
                            <TextSection
                                embroiderer={embroiderer}
                                embroideryPage={embroideryPage}
                                isShowTitle={false}
                                isSectionInvalid={isSectionInvalid}
                                textError={textError}
                                setTextError={setTextError}
                            />
                        </div>
                        <div className={styles.subTitle}>{t('styling')}</div>
                        <div className={styles.actionButtonWrapper}>
                            {
                                embroideryPage.colorValue ? (
                                    <ColorStylePreview
                                        embroideryPage={embroideryPage}
                                        handleAction={() => action.colorAction(embroideryPage)}
                                    />
                                ) : (
                                    <AccessibleButton
                                        tag="div"
                                        data-test="emb-text-color-btn"
                                        className={classes({
                                            [styles.actionButton]: true,
                                            [styles.invalidButton]: action.isValidStyle(embroideryPage),
                                        })}
                                        action={() => action.colorAction(embroideryPage)}
                                    >
                                        <span className={styles.stepCircle}>{t('1')}</span>
                                        <span className={styles.title}>{t('color')}</span>
                                    </AccessibleButton>
                                )
                            }
                            {embroideryPage.colorValue && embroideryPage.font && <SubDivider />}
                            {
                                embroideryPage.font ? (
                                    <FontStylePreview
                                        embroideryPage={embroideryPage}
                                        handleAction={() => action.fontAction(embroideryPage)}
                                    />
                                ) : (
                                    <AccessibleButton
                                        tag="div"
                                        data-test="emb-text-font-btn"
                                        className={classes({
                                            [styles.actionButton]: true,
                                            [styles.invalidButton]: action.isValidStyle(embroideryPage),
                                        })}
                                        action={() => action.fontAction(embroideryPage)}
                                    >
                                        <span className={styles.stepCircle}>{t('2')}</span>
                                        <span className={styles.title}>{t('font')}</span>
                                    </AccessibleButton>
                                )
                            }
                            {embroideryPage.font && embroideryPage.placement && <SubDivider />}
                            {
                                embroideryPage.placement ? (
                                    <PlacementStylePreview
                                        embroideryPage={embroideryPage}
                                        handleAction={() => action.placementAction(embroideryPage)}
                                    />
                                ) : (
                                    <AccessibleButton
                                        tag="div"
                                        data-test="emb-text-placement-btn"
                                        className={classes({
                                            [styles.actionButton]: true,
                                            [styles.invalidButton]: action.isValidStyle(embroideryPage),
                                        })}
                                        action={() => action.placementAction(embroideryPage)}
                                    >
                                        <span className={styles.stepCircle}>{t('3')}</span>
                                        <span className={styles.title}>{t('alignment')}</span>
                                    </AccessibleButton>
                                )
                            }
                            {
                                (embroideryPage.colorValue || embroideryPage.font || embroideryPage.placement) && (
                                    <div style={{ textAlign: 'center' }}>
                                        <AccessibleButton
                                            tag="div"
                                            data-test="emb-text-remove-styles-btn"
                                            className={classes({ [styles.actionLink]: true })}
                                            action={() => {
                                                action.removeStyleAction(embroideryPage);
                                            }}
                                        >
                                            <span>{t('remove all styles')}</span>
                                        </AccessibleButton>
                                    </div>
                                )
                            }
                        </div>
                    </>
                )}
            </TextComponents.State>
        </div>
    );
};
