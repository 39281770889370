import React from 'react';
import { types } from '../types';

export class State extends React.Component<any> {
    render() {
        const { children, embroiderer } = this.props;
        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                isValid: option => types[option.type].isValid(option),
                openOption: option => () => {
                    embroiderer.pushView(option.type);
                },
                toggleOption: option => () => {
                    if (!types[option.type].isValid(option)) {
                        embroiderer.pushView(option.type);
                    } else {
                        embroiderer.saveOption(
                            types[option.type].clear(option),
                        );
                    }
                },
                openSubOption: (option, subOption = null) => {
                    if (subOption) {
                        embroiderer.pushView(option.type, subOption);
                    }
                },
            },
        });
    }
}
