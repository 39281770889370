import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Flags } from './flags';
import { IEmbroideryParams } from '../embroidery';
import { FlagRoles } from '../flag-roles';

export const SameFlags: React.FC<IEmbroideryParams> = (
    {
        embroiderer,
        embroideryPage,
    },
) => (
    <OptionPage
        embroiderer={embroiderer}
        embroideryPage={embroideryPage}
    >
        <Flags
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
            type={FlagRoles.flag}
        />
    </OptionPage>
);
