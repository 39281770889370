import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface IPageHeaderProps {
    handleBackClick: () => void;
    type: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ handleBackClick, type }) => {
    const t = usePhraseTranslater();
    const staticTitleLabel: {[key: string]: string} = {
        text: t('Text Style'),
        logos: t('Logos'),
        flags: t('Flags'),
        'special-instructions': '',
        confirmation: t('Confirmation'),
    };
    // Ensure the type exists in the staticTitleLabel object before accessing it
    const titleLabel = staticTitleLabel[type] || '';
    return (
        <div className={styles.header}>
            <AccessibleButton
                id="emb-page-backbutton"
                tag="i"
                className="material-icons"
                action={handleBackClick}
            >
                chevron_left
            </AccessibleButton>
            {t(titleLabel)}
        </div>
    );
};
