import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const SaveButtonEc = ({ action }) => {
    const t = usePhraseTranslater();
    return (
        <AccessibleButton
            tag="div"
            data-test="save-embroidery-ec"
            action={action.saveEc}
            className={classes(styles.saveButtonEcPopup, {
                [styles.disabled]: action.cannotSave,
            })}
        >
            {t('SAVE')}
        </AccessibleButton>

    );
};
