import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

const defaultTestFun = _ => null;

const baseStyle = (styleSelector, item) => {
    if (styleSelector) {
        return styleSelector(item);
    }

    return {};
};

const fontStyle = (fontSelector, item) => {
    if (fontSelector) {
        return {
            fontFamily: `'${fontSelector(item)}'`,
        };
    }

    return {};
};

const getPreviousItem = (isSelected, items) => {
    // Find the index of the selected item
    const selectedIndex = items.findIndex(isSelected);

    // Check if the selected item exists and has a previous item
    if (selectedIndex !== -1 && selectedIndex > 0) {
        return items[selectedIndex - 1];
    }
    // If no previous item, return the last item
    return items[items.length - 1];
};

const getNextItem = (isSelected, items) => {
    // Find the index of the selected item
    const selectedIndex = items.findIndex(isSelected);

    // Check if the selected item exists and has a next item
    if (selectedIndex !== -1 && items[selectedIndex + 1]) {
        return items[selectedIndex + 1];
    }
    // If no next item, return the first item
    return items[0];
};

interface TextSwatchesProps<T> {
    items: T[];
    keySelector: (item: T) => string;
    handleChangeSelector: (
        (item: T) =>
        (e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) =>
        void
    );
    labelSelector: any;
    isSelected: (item: T) => boolean;
    styleSelector?: (item: T) => any;
    fontSelector?: (item: T) => string;
    dataTestFun?: (item: T) => string;
    className?: string;
    enableNavigation?: boolean;
    shouldDisable?: (item: T) => boolean;
    disabledByIndex?: (item: T) => number;
}

/* eslint-disable arrow-parens */
export const TextSwatches = <T, >({
    items,
    keySelector,
    handleChangeSelector,
    labelSelector,
    isSelected,
    styleSelector,
    fontSelector,
    dataTestFun,
    className,
    enableNavigation,
    shouldDisable,
    disabledByIndex,
}: TextSwatchesProps<T>) => {
    const getTest = dataTestFun || defaultTestFun;
    const t = usePhraseTranslater();
    return (
        <ul className={classes({
            [styles.fontListWrap]: true,
            [className]: className,
        })}
        >
            {enableNavigation && (
                <AccessibleButton
                    tag="li"
                    action={handleChangeSelector(getPreviousItem(isSelected, items))}
                    className={styles.fontItemPrevious}
                >
                    <i className="fa-solid fa-arrow-left" />
                </AccessibleButton>
            )}
            {items.map(item => {
                const isDisabled = shouldDisable ? shouldDisable(item) : false;
                const itemDisabledByIndex = disabledByIndex ? disabledByIndex(item) : -1;
                return (
                    <React.Fragment key={keySelector(item)}>
                        <AccessibleButton
                            tag="li"
                            className={classes({
                                [styles.fontItem]: true,
                                [styles.fontItemActive]: isSelected(item),
                                [styles.fontItemDisabled]: isDisabled,
                            })}
                            style={{
                                ...baseStyle(styleSelector, item),
                                ...fontStyle(fontSelector, item),
                            }}
                            data-test={getTest(item)}
                            action={handleChangeSelector(item)}
                            isLocked={isDisabled}
                        >
                            {labelSelector(item)}
                            {disabledByIndex && itemDisabledByIndex !== -1 && (
                                <span>
                                    {t('logo ')}
                                    {itemDisabledByIndex + 1}
                                </span>
                            )}
                        </AccessibleButton>
                        {' '}
                    </React.Fragment>
                );
            })}
            {enableNavigation && (
                <AccessibleButton
                    tag="li"
                    action={handleChangeSelector(getNextItem(isSelected, items))}
                    className={styles.fontItemNext}
                >
                    <i className="fa-solid fa-arrow-right" />
                </AccessibleButton>
            )}
        </ul>
    );
};
/* eslint-enable */
