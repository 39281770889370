import { types } from '../types';
import { Configure } from './configure';
import { State } from './state';
import { clear } from './clear';
import { layers } from './layers';
import { isValid } from './is-valid';
import { price } from './price';

types['print-screen'] = {
    Configure,
    State,
    clear,
    layers,
    isValid,
    price,
};
