import { useState, useEffect } from 'react';

export const useValidatedInput = ({ isValid, onChange, value }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (isValid(inputValue)) {
            onChange(inputValue);
        }
    }, [inputValue]);

    return [inputValue, setInputValue, isValid(inputValue)];
};
