import React from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import { FlagPreview } from '../flag-preview';
import styles from './style.css';

export const FlagContainer = ({ embroiderer, parentEmbroideryPage, option }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const currentPageData = extractState(embroiderer, 'flags');
    const FlagComponents = types.flags;
    const t = usePhraseTranslater();
    const action = {
        selectFlagAction: () => {
            parentEmbroideryPage.openSubOption(currentPageData, { subType: 'flag1' });
        },
        removeFlags: (embroideryPage) => {
            embroideryPage.clearFlags();
        },
    };
    return (
        <div className={styles.flagContainer}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <FlagComponents.State embroiderer={embroiderer} data={currentPageData}>
                {({ embroideryPage }) => {
                    if (embroideryPage.flag || embroideryPage.flag1 || embroideryPage.flag2) {
                        return (
                            <>
                                <Price
                                    price={types[option.type].price(
                                        option,
                                        embroiderer.embroideryState,
                                        embroiderer.quantity,
                                    )}
                                    dataTest="embroidery-price-summary-flags"
                                    className={styles.addOnPrice}
                                />
                                <FlagPreview
                                    embroiderer={embroiderer}
                                    embroideryPage={embroideryPage}
                                    parentEmbroideryPage={parentEmbroideryPage}
                                />
                                <div style={{ textAlign: 'center' }}>
                                    <AccessibleButton
                                        tag="div"
                                        data-test="emb-flags-remove-styles-btn"
                                        className={classes({ [styles.actionLink]: true })}
                                        action={() => {
                                            action.removeFlags(embroideryPage);
                                        }}
                                    >
                                        <span>{t('remove flags')}</span>
                                    </AccessibleButton>
                                </div>
                            </>
                        );
                    }
                    return (
                        <>
                            <div className={classes(styles.actionButtonWrapper, styles.actionButtonCompactWrapper)}>
                                <Price
                                    price={embroideryPage.price}
                                    className={styles.price}
                                />
                                <AccessibleButton
                                    tag="div"
                                    data-test="emb-flag-select-btn"
                                    className={classes({ [styles.actionButton]: true })}
                                    action={action.selectFlagAction}
                                >
                                    <span className={styles.title}>{t('select')}</span>
                                </AccessibleButton>
                            </div>
                        </>
                    );
                }}
            </FlagComponents.State>
        </div>
    );
};
