import React from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import styles from './style.css';

interface ISpecialInstructionsTextAreaProps {
    embroideryPage: {
        label: string;
        sku: string;
        price: number;
    };
}

const PrintScreenArea: React.FC<ISpecialInstructionsTextAreaProps> = ({ embroideryPage }) => {
    const t = usePhraseTranslater();

    return (
        <>
            <div className={styles.actionCustomisationArea}>
                {t(embroideryPage.label)}
                {' '}
                <span>{embroideryPage.sku && embroideryPage.sku}</span>
            </div>
            <Price
                price={embroideryPage.price}
                className={styles.price}
            />
        </>
    );
};

// @ts-ignore
export const PrintScreenContainer = ({ embroiderer, option }) => {
    const sku = option?.sku;
    let currentPageData = extractState(embroiderer, 'print-screen');
    if (sku && typeof sku === 'string') {
        [currentPageData] = embroiderer.options.filter((x: { type: string; sku: string }) => x.type === 'print-screen' && x.sku === sku);
    }
    const AlterationPipingConatiner = types['print-screen'];
    return (
        <div className={styles.instructionContainer}>
            <AlterationPipingConatiner.State embroiderer={embroiderer} data={currentPageData}>
                {({ embroideryPage }: { embroideryPage: { sku: string; label: string; price: number } }) => (
                    <>
                        <PrintScreenArea embroideryPage={embroideryPage} />
                    </>
                )}
            </AlterationPipingConatiner.State>
        </div>
    );
};
