import React from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { TextSwatches } from 'ui/component/embroidery-configurator/components/text-swatches';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const PlacementSection = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <SectionTitle>{t('Placement')}</SectionTitle>
            <Section>
                <TextSwatches
                    items={embroideryPage.placementOptions as any[]}
                    isSelected={p => p.frontendValue === embroideryPage.placement}
                    labelSelector={p => p.frontendValue}
                    keySelector={p => p.frontendValue}
                    dataTestFun={item => `placement-${item.frontendValue}`}
                    handleChangeSelector={p => () => {
                        embroiderer.setFace(p.face)();
                        embroideryPage.setPlacement(p.frontendValue)();
                        embroideryPage.setLiquidPixelPlacement(p.backendValue)();
                    }}
                />
            </Section>
        </>
    );
};
