import React from 'react';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IPriceSummaryProps {
    text: string;
    price: number;
    dataTest: string;
    isEmbroideryPrice?: boolean;
}

export const PriceSummary: React.FC<IPriceSummaryProps> = ({
    text,
    price,
    dataTest,
    isEmbroideryPrice,
}) => (
    <div className={styles.priceSummary}>
        <div className={styles.priceText}>{text}</div>
        <Price
            price={price}
            className={classes({
                [styles.price]: true,
                [styles.embPrice]: isEmbroideryPrice,
            })}
            noPlus
            dataTest={dataTest}
        />
    </div>
);
