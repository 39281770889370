import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Constraints } from 'ui/component/embroidery-configurator/pages/logos/configure/constraints';
import { Title } from '../title';
import styles from './style.css';

interface ISectionProps {
    className: string;
    label: string;
    optionType: string;
    handleEdit?: () => void;
    handleToggle?: () => void;
    isLocked?: boolean;
    isSectionInvalid?: boolean;
}

export const Section = ({
    children,
    className,
    label,
    optionType,
    isLocked,
    isSectionInvalid,
}: React.PropsWithChildren<ISectionProps>) => {
    const t = usePhraseTranslater();
    if (isLocked) return null;
    return (
        <div
            id={`emb-section-${className}`}
            className={classes({
                [styles.section]: true,
                [className]: true,
            })}
        >
            <div className={styles.sectionTitle}>
                <Title>
                    {t(label)}
                    {optionType === 'logos' && <Constraints />}
                </Title>
            </div>
            <div
                className={classes({
                    [styles.sectionBody]: true,
                    [styles.sectionInvalid]: isSectionInvalid,
                })}
            >
                {children}
            </div>
        </div>
    );
};
