/* eslint-disable */
import styles from './style.css';
import React, { useState } from 'react';
import { SaveButtonEc } from '../save-button-ec';
import { CancelButtonEc } from '../cancel-button-ec';
import {useContainerHook} from "@silkpwa/redux";
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';

export const SaveEcPopup = ({close, embroiderer, text}) => {
    const t = usePhraseTranslater();
    const queryString = useContainerHook<() => any>('useQueryString');
    const pname = (queryString.productname) ? (queryString.productname).replace(/\++/g, ' ') : '';
    const pdetail = (queryString.description) ? (queryString.description).replace(/\++/g, ' ') : '';
    const entityId = (queryString.entity_id) ? (queryString.entity_id) : '';
    const itemId = (queryString.item_id) ? (queryString.item_id) : '';
    const [productname, setProductName] = useState(decodeURIComponent(pname));
    const [description, setDescription] = useState(decodeURIComponent(pdetail));
    localStorage.setItem('productname', productname);
    localStorage.setItem('description', description);
    localStorage.setItem('entity_id', entityId);
    localStorage.setItem('item_id', itemId);
    let url = embroiderer;
    if (!localStorage.getItem('productname') || !localStorage.getItem('description')) {
        url = '';
    }
    return (
        <form className={styles.saveEcForm}>
            <div className={styles.saveEcPopup}>
                <div className={styles.overlayHeader}>
                    <div className={styles.overlayTitle}>
                        {text}
                    </div>
                    <div className={styles.overlayAction}>
                        <AccessibleButton
                            tag="span"
                            action={close}
                        >
                            <i className="fa-solid fa-xmark" />
                        </AccessibleButton>
                    </div>
                </div>
                <div className={styles.overlayContent}>
                    <div className={styles.grid}>
                        <label className={styles.input_label}>{t('Product Name:')}
                            <span className={styles.req}> *</span></label>
                        <input maxLength = {150} className={styles.input_text} type="text" id="productname"
                            value={productname} name="productname" required={true}
                            onChange={event => setProductName(event.target.value)}/>
                        <label className={styles.input_label}>{t('Description:')}
                            <span className={styles.req}> *</span></label>
                        <textarea maxLength = {250} className={styles.input_textarea} id="description" value={description}
                                name="description" required={true}
                                onChange={event => setDescription(event.target.value)}/>
                        <div className={styles.saveEcPopupActionWrapper}>
                            <CancelButtonEc action={close} className={styles.cancelButtonEcPopup} />
                            <SaveButtonEc action={url} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
