import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const Disclaimer = () => {
    const t = usePhraseTranslater();
    return (
        <span className={styles.disclaimer}>
            {t('Note: The final placement, size and spacing of embroidery might appear slightly different.')}
        </span>
    );
};
