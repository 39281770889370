import React from 'react';
import { enhanceState } from '@silkpwa/module/util/react-state-enhancer';
import { extractState } from '../util';
import { IConfirmationData } from './configure';
import { IEmbroideryOption } from '../../api/embroidery-interfaces';

import ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface =
    Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

export class State extends React.Component<any, IEmbroideryOption> {
    static emptyLogo(isRemoved = false) {
        return {
            logo: null,
            side: '',
            placement: '',
            isRemoved,
        };
    }

    private removeIn: (path: string | (string | number)[], onFinish: () => void) => void;

    private setIn: (path: string | (string | number)[], value: null | object | [], onFinish?: () => void) => void;

    constructor(props: any) {
        super(props);
        const { embroiderer } = this.props;

        const currentPageData = extractState(embroiderer, 'logos');

        this.state = {
            ...currentPageData,
        };

        this.confirmedYes = this.confirmedYes.bind(this);
        this.confirmedNo = this.confirmedNo.bind(this);
    }

    hasPersonalizationRule(logoNum: number): boolean {
        const { embroiderer } = this.props;
        return embroiderer.allowedLogoNumbers.filter(
            (
                rule: ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface,
            ) => rule.line_number === logoNum + 1,
        ).length > 0;
    }

    /**
     * 1. if personalization rule is not applied
     * - it will remove the logo from the position (valid to shift up rest of the logo)
     *
     * 2. if personalization rule is applied
     * - a. check for all the empty logo in sequence till list logo and remove that sequence
     * e.g
     * - [logo1, logo removed, logo removed, logo4 (uer wants to remove this)] => result [logo 1]
     * - [logo1, logo removed, logo 3, logo4 (uer wants to remove this)] => result [logo 1, logo removed, logo 3]
     *
     * @param logoNum
     * @private
     */
    private removeLogo(logoNum: number): void {
        if (!this.hasPersonalizationRule(logoNum)) {
            this.removeIn(['logos', logoNum], () => this.save());
        } else {
            // @ts-ignore
            const { logos } = this.state;
            let newLogos = logos;
            const lastLogoIndex = logos.length - 1;
            if (lastLogoIndex === logoNum) {
                newLogos.pop();
                const lastIndex = newLogos.findLastIndex(logo => logo.logo !== null);
                if (lastIndex !== -1) {
                    newLogos = newLogos.slice(0, lastIndex + 1);
                    newLogos.push(State.emptyLogo());
                } else {
                    newLogos = [State.emptyLogo()];
                }
                this.setIn(['logos'], newLogos, () => this.save());
            } else {
                newLogos = newLogos.slice(0, logoNum).concat(newLogos.slice(logoNum + 1));
                const lastIndex = newLogos.findLastIndex(logo => logo.logo !== null);
                if (lastIndex === -1) {
                    newLogos = [State.emptyLogo()];
                    this.setIn(['logos'], newLogos, () => this.save());
                } else {
                    this.setIn(['logos', logoNum], State.emptyLogo(true), () => this.save());
                }
            }
        }
    }

    private save(): void {
        this.back();
    }

    confirmedYes(data: IConfirmationData): void {
        if (data.logoNumber === undefined || data.logoNumber < 0) return;
        this.removeLogo(data.logoNumber);
    }

    confirmedNo(): void {
        this.back();
    }

    private back(): void {
        const { embroiderer } = this.props;
        embroiderer.saveOption(this.state);
        embroiderer.popView();
    }

    render() {
        const { children } = this.props;
        if (typeof children !== 'function') return null;
        return children({
            embroideryPage: {
                ...this.state,
                type: 'confirmation',
                confirmedYes: this.confirmedYes,
                confirmedNo: this.confirmedNo,
            },
        });
    }
}

enhanceState(State);
