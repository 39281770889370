import { FlagRoles } from '../flag-roles';
import { IEmbroideryPage } from '../embroidery';

export type FlagDataByType = {
    title: string;
    subtitle: string;
    description: string;
    price: number;
    value: string;
};

export const flagsData = (
    embroideryPage: IEmbroideryPage,
    t: any,
    type: 'flag' | 'flag1' | 'flag2',
): FlagDataByType => {
    let title: string;
    let subtitle: string;
    let description: string;
    let price: number;
    const embroideryPrice: number = embroideryPage.price;
    switch (type) {
        case FlagRoles.flag1:
            title = t('Flag1');
            subtitle = t('Left Collar');
            description = '';
            price = (embroideryPrice / 2);
            break;
        case FlagRoles.flag2:
            title = t('Flag2');
            subtitle = t('Right Collar');
            description = '';
            price = (embroideryPrice / 2);
            break;
        case FlagRoles.flag:
        default:
            title = t('Flags');
            subtitle = '';
            description = '';
            price = embroideryPrice;
            break;
    }

    const value: string = embroideryPage[type];
    const flagData: FlagDataByType = {
        title,
        subtitle,
        description,
        price,
        value,
    };

    return flagData;
};
