import React from 'react';
import { clear } from './clear';
import { FlagRoles } from './flag-roles';
import { isValid, isValidFlag1, isValidFlag2 } from './is-valid';
import { extractState, nextPage } from '../util';
import { price } from './price';
import { IFlagPlacements, FlagPositions } from './embroidery';

export class State extends React.Component<any> {
    constructor(props) {
        super(props);
        const { embroiderer } = props;
        this.state = extractState(embroiderer, 'flags');
    }

    setFlag(flag: string, type: string): void | boolean {
        switch (type) {
            case FlagRoles.flag:
                return this.setState({ flag });
            case FlagRoles.flag1:
                return this.setState({ flag1: flag });
            case FlagRoles.flag2:
                return this.setState({ flag2: flag });
            default:
                return false;
        }
    }

    setCheckBoxValue(checkbox: boolean): Function {
        return () => this.setState({ checkbox });
    }

    setFlagPosition(
        flagPlacements: IFlagPlacements[],
        flagPosition: FlagPositions,
        flagType: string,
    ): void {
        let flag1Position: FlagPositions = '';
        let flag2Position: FlagPositions = '';
        const flagPlacement = flagPlacements.find(obj => obj.backendValue !== flagPosition);
        if (flagType === FlagRoles.flag1 && flagPlacement) {
            flag1Position = flagPosition;
            flag2Position = flagPlacement.backendValue;
        } else if (flagType === FlagRoles.flag2 && flagPlacement) {
            flag1Position = flagPlacement.backendValue;
            flag2Position = flagPosition;
        }

        this.setState({
            flag1Position,
            flag2Position,
        });
    }

    save() {
        const { embroiderer } = this.props;
        if (isValid(this.state)) {
            embroiderer.saveOption(this.state);
        }
    }

    clear() {
        return this.setState(clear);
    }

    clearFlags() {
        const saveOption = () => {
            const { embroiderer } = this.props;
            embroiderer.saveOption(this.state);
        };
        this.setState(clear, saveOption.bind(this));
    }

    render() {
        const { children, embroiderer } = this.props;

        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                setFlag: this.setFlag.bind(this),
                setCheckBoxValue: this.setCheckBoxValue.bind(this),
                setFlagPosition: this.setFlagPosition.bind(this),
                save: this.save.bind(this),
                clear: this.clear.bind(this),
                clearFlags: this.clearFlags.bind(this),
                canSave: isValid(this.state),
                nextPage: nextPage(embroiderer, 'flags'),
                price: price(
                    this.state,
                    embroiderer.embroideryState,
                    embroiderer.quantity,
                ),
                isValidFlag1: isValidFlag1(this.state),
                isValidFlag2: isValidFlag2(this.state),
            },
        });
    }
}
