import React from 'react';
import styles from './style.css';

interface ISubsectionTitleProps {
    children?: React.ReactNode;
}

export const SubsectionTitle: React.FC<ISubsectionTitleProps> = ({ children }) => (
    <div className={styles.sectionTitle}>{children}</div>
);
