/**
 * In order to return price correctly we have to validate its line visibility and content:
 *  - only visible lines should be taken into account;
 *  - if visible - it should not be empty in order to return its price.
 *
 * @param state
 * @param i
 */
const isTextVisibleAndNotEmpty = (state, i: number): boolean => Boolean(
    state.linesVisibility && state.linesVisibility[i] &&
    state.lines && state.lines[i] && state.lines[i].length > 0,
);

export const price = (state, _1, quantity) => state.lines
    .map((_2, i) => isTextVisibleAndNotEmpty(state, i) && state.priceForLine[i] * quantity)
    .reduce((acc, x) => acc + x, state.basePrice);
