import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { FlagRoles } from 'ui/component/embroidery-configurator/pages/flags/flag-roles';
import styles from './style.css';

export const NextButton = ({ embroideryPage, viewData, onClick }) => {
    const t = usePhraseTranslater();
    const isValidSection = (subOptionType: string): boolean => {
        switch (subOptionType) {
            case FlagRoles.flag:
                return embroideryPage.canSave;
            case FlagRoles.flag1:
                return embroideryPage.isValidFlag1;
            case FlagRoles.flag2:
                return embroideryPage.isValidFlag2;
            default:
                return embroideryPage.canSave;
        }
    };

    return isValidSection(viewData.subType) && (
        <AccessibleButton
            tag="div"
            data-test="emb-flag-next-btn"
            className={classes({
                [styles.nextButton]: true,
                [styles.save]: isValidSection(viewData.subType),
            })}
            isLocked={!isValidSection(viewData.subType)}
            action={() => true}
            onClick={onClick}
        >
            <span>{t('Next')}</span>
        </AccessibleButton>
    );
};
