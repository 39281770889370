import React from 'react';
import { TextInput } from 'ui/component/embroidery-configurator/components/text-input';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { LogoValueObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { LogoResponseData } from 'ui/component/embroidery-configurator/api/embroidery-repository';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { LogoOnFileSelector } from './logo-on-file-selector';
import { LogoSignInBox } from './logo-sign-in-box';
import styles from './style.css';

interface LogoInputProps {
    account: AccountState;
    valueObj: LogoValueObject;
    dataTest: string;
    handleChange: (v) => void;
    handleRemove: (v) => void;
    embroideryLogoEnabled: boolean;
    liquidPixelEnabled: boolean;
    handleLoadLogos: () => Promise<null | LogoResponseData>;
    isDefaultAndLocked: boolean;
    allowedLogos: string[];
}

const LogoInput: React.FC<LogoInputProps> = ({
    account,
    valueObj,
    dataTest,
    handleChange,
    handleRemove,
    embroideryLogoEnabled,
    liquidPixelEnabled,
    handleLoadLogos,
    isDefaultAndLocked,
    allowedLogos,
}) => {
    const t = usePhraseTranslater();
    const config = useConfig();
    const onfileLogoUserInputAlwaysShow = (
        config.extension_attributes?.embroidery_logo_onfile_user_input_show
    );
    const showOnfileLogoInput = !account.isLoggedIn || onfileLogoUserInputAlwaysShow;
    return (
        <div className={styles.inputWrap}>
            {embroideryLogoEnabled ? (
                <>
                    <>
                        {!account.isLoggedIn ? (
                            <LogoSignInBox />
                        ) : (
                            <>
                                <LogoOnFileSelector
                                    valueObj={valueObj}
                                    isDefaultAndLocked={isDefaultAndLocked}
                                    handleChange={(value) => {
                                        handleChange(value);
                                    }}
                                    handleLoadLogos={handleLoadLogos}
                                    allowedLogos={allowedLogos}
                                    handleRemove={handleRemove}
                                />
                            </>
                        )}
                    </>
                    {showOnfileLogoInput ? (
                        <>
                            <span className={styles.orSeparator}>
                                {t('OR')}
                            </span>
                            <span className={styles.designNumberInputLabel}>
                                {t('type design number')}
                            </span>
                        </>
                    ) : ''}
                </>
            ) : ''}
            <TextInput
                value={valueObj ? valueObj.value : ''}
                className={
                    embroideryLogoEnabled && !showOnfileLogoInput ? styles.hide : styles.designNumberInputWrapper
                }
                style={{ color: '#000' }}
                dataTest={dataTest}
                placeholder={t('Name or Design Number')}
                handleChange={handleChange}
            />
            {embroideryLogoEnabled && liquidPixelEnabled && showOnfileLogoInput ? (
                <span className={styles.disclaimer}>
                    {t('**placeholder logo will appear to visualize size, spacing & placement')}
                </span>
            ) : null}
        </div>
    );
};

const ConnectedLogoInput = connectAccount(LogoInput);

export { ConnectedLogoInput as LogoInput };
