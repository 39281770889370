import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { DownloadButton } from 'ui/component/embroidery-configurator/pages/start/configure/download-button';
import {
    DownloadSignInBox,
} from 'ui/component/embroidery-configurator/pages/start/configure/download-button/download-sign-in-box';
import { SubmitEcButton } from 'ui/component/embroidery-configurator/pages/start/configure/submit-button-ec';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ILeftActionsProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
    isSticky: boolean;
}

export const LeftActions: React.FC<ILeftActionsProps> = ({ account, embroiderer, isSticky }) => (
    <div className={
        classes({
            [styles.leftActions]: true,
            [styles.sticky]: isSticky,
        })}
    >
        <DownloadButton account={account} embroiderer={embroiderer} />
        <DownloadSignInBox account={account} embroiderer={embroiderer} />
        <SubmitEcButton account={account} embroiderer={embroiderer} />
    </div>
);
