import React from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import styles from './style.css';

const getImages = (embroiderer, embroideryPage) => {
    const { face } = embroiderer;

    if (!face) return [];

    // compute layers from face & images
    const layers = [face.image];
    embroiderer.options.forEach((o) => {
        const option = o.type === embroideryPage.type ? embroideryPage : o;

        types[o.type].layers(option, face.name).forEach((l) => {
            layers.push(l);
        });
    });

    return layers.map((x) => {
        if (typeof x === 'string') {
            return { src: x };
        }
        return x;
    });
};

export const Images = ({
    embroiderer, embroideryPage, forwardRef, children,
}) => (
    <div className={styles.images} ref={forwardRef}>
        {getImages(embroiderer, embroideryPage).map((image, i) => (
            /* eslint-disable react/no-array-index-key */
            <img
                key={i}
                src={image.src}
                className={image.className}
                style={{ zIndex: i }}
                alt=""
            />
            /* eslint-enable */
        ))}
        {children}
    </div>
);
