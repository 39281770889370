import React, { useCallback, useEffect, useState } from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ErrorMessage } from 'ui/component/embroidery-configurator/components/error-message';
import _ from 'lodash';
import styles from './style.css';

interface ISpecialInstructionsTextAreaProps {
    embroideryPage: any; // TODO: add typing
}

const SpecialInstructionsTextArea: React.FC<ISpecialInstructionsTextAreaProps> = ({ embroideryPage }) => {
    const t = usePhraseTranslater();
    const [specialText, setSpecialText] = useState(embroideryPage?.rule?.special_instructions ?? '');
    const isLocked = Boolean(embroideryPage?.rule?.locked === '1' ?? false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const debouncedInput = useCallback(
        _.debounce((value) => {
            setSpecialText(value);
            embroideryPage.setText(value);
        }, 500),
        [],
    );

    const handleTextChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = ev.target.value;
        if (text.length >= 255) {
            setShowError(true);
            setErrorMessage('Text cannot exceed 255 characters.');
        } else {
            setShowError(false);
            setErrorMessage('');
            setSpecialText(text);
            debouncedInput(text);
        }
    };

    useEffect(() => {
        setSpecialText(embroideryPage.text);
    }, [embroideryPage.text]);

    return (
        <>
            <div className={styles.specialInstructions}>
                <textarea
                    id="special-instructions"
                    data-test="special-instructions"
                    className={styles.instructions}
                    maxLength={255}
                    onChange={handleTextChange}
                    placeholder={t('Special Instructions')}
                    disabled={isLocked}
                    defaultValue={specialText}
                />
                <ErrorMessage show={showError} message={errorMessage} />
            </div>
        </>
    );
};

export const SpecialInstructionsContainer = ({ embroiderer }) => {
    const currentPageData = extractState(embroiderer, 'special-instructions');
    const SpecialInstructionsComponents = types['special-instructions'];

    return (
        <div className={styles.instructionContainer}>
            <SpecialInstructionsComponents.State embroiderer={embroiderer} data={currentPageData}>
                {({ embroideryPage }) => (
                    <SpecialInstructionsTextArea embroideryPage={embroideryPage} />
                )}
            </SpecialInstructionsComponents.State>
        </div>
    );
};
