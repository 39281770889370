import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { PriceSummary } from './price-summary';
import styles from './style.css';

export const Subtotal = ({ price }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.subtotal}>
            <PriceSummary price={price} text={t('Subtotal')} dataTest="subtotal-summary" />
        </div>
    );
};
