import { selectedValue } from '../util';

export const layers = (state, face) => {
    if (!state.placement) return [];

    const placement = selectedValue(
        state.placementOptions,
        state.placement,
        x => x,
    );

    if (!placement) return [];

    if (placement.face !== face) return [];

    return [placement.image];
};
