import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { StockStatus } from 'ui/component/product-configurator/product-info/stock-status';
import { QuantityMessage } from 'ui/component/quantity-message';
import styles from './style.css';

interface IQuantityProps {
    quantity: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    simpleProduct: any;
}

const Quantity: React.FC<IQuantityProps> = ({ quantity, simpleProduct }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <div className={classes(styles.quantityBox)}>
                <div className={styles.label}>
                    {t('Quantity:')}
                </div>
                <div className={styles.quantityBoxContent}>
                    <div className={styles.quantity}>
                        <button
                            onClick={quantity.decrement}
                            data-test="btn-qty-decr"
                            type="button"
                        >
                            -
                        </button>
                        <input
                            type="text"
                            value={quantity.current}
                            onChange={e => quantity.set(Number(e.target.value))}
                            onClick={e => (e.target as HTMLInputElement).select()}
                            data-test="qty-input"
                        />
                        <button
                            onClick={quantity.increment}
                            data-test="btn-qty-incr"
                            type="button"
                        >
                            +
                        </button>
                    </div>
                    <div className={styles.quantityStockStatusWrapper}>
                        <StockStatus
                            product={simpleProduct}
                            parentClass={styles.quantityStockStatus}
                            type="footer"
                        />
                    </div>
                </div>
            </div>
            <div className={classes(styles.quantityMessageWrapper)}>
                <QuantityMessage
                    className={styles.quantityMessage}
                    quantity={quantity.current}
                    product={simpleProduct}
                />
            </div>
        </>
    );
};

interface IQuantityButtonsProps {
    embroiderer: EmbroidererProps;
}

export const QuantityButtons: React.FC<IQuantityButtonsProps> = ({ embroiderer }) => (
    <Quantity
        quantity={embroiderer.quantityState}
        simpleProduct={embroiderer.product}
    />
);
