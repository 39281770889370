import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { WishlistButton } from 'ui/component/embroidery-configurator/components/add-to-cart-summary/wishlist-button';
import styles from './style.css';

interface ISubmitButtonProps {
    embroiderer: EmbroidererProps;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = ({ embroiderer }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.submitButtonWrap}>
            <AccessibleButton
                tag="div"
                data-test="submit-embroidery"
                action={embroiderer.save}
                className={classes(styles.saveButton, {
                    [styles.disabled]: embroiderer.cannotSave,
                })}
            >
                {embroiderer.isQuickView ? t('update item') : t('add to cart')}
            </AccessibleButton>
            <WishlistButton productId={embroiderer.product.id} />
        </div>
    );
};
