import React from 'react';
import { IImageInfo } from '../../api/embroidery-interfaces';
import styles from './style.css';

export const isImageFile = (src: string): boolean => {
    const srcSplit = src.split('.');
    const nonImgExtensions = ['eps', 'dst', 'tiff'];
    return !nonImgExtensions.includes(srcSplit[srcSplit.length - 1].toLowerCase());
};

interface ILogoPreviewProps {
    image: IImageInfo;
}

export const LogoPreview: React.FC<ILogoPreviewProps> = ({ image }) => (
    <div className={styles.logoPreview}>
        {isImageFile(image.url) ? (
            <div className={styles.image}>
                <img src={image.url} alt={image.altText} />
            </div>
        ) : (
            <div className={styles.detail}>
                <span className={styles.altText}>
                    {image.altText}
                </span>
            </div>
        )}
    </div>
);
