import React from 'react';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { createPortal } from 'react-dom';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';
import { ShowSaveEcPopup } from './show-save-ec-popup';

export const SaveEcPopupModal = ({
    ec,
    embroiderer,
    children,
    text,
}) => {
    const validation = !embroiderer.cannotSave;
    return (
        <ModalSystemConsumer>
            {({ open: showEc, setOpen: setShowEc }) => (
                <React.Fragment>
                    {children(() => setShowEc(validation))}
                    {showEc && createPortal(
                        <ShowSaveEcPopup
                            ec={ec}
                            close={() => setShowEc(false)}
                            embroiderer={embroiderer}
                            text={text}
                        />,
                        getEmbeddedEmbConfiguratorElement(),
                    )}
                </React.Fragment>
            )}
        </ModalSystemConsumer>
    );
};
