import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import {
    ISubOptions,
    subOptions,
    SubOptionsType,
} from 'ui/component/embroidery-configurator/pages/text/util/sub-options';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import styles from './style.css';

export const Pagination = ({ embroideryPage, embroiderer, viewData }) => {
    const t = usePhraseTranslater();
    const currentPageData = extractState(embroiderer, 'text');
    return (
        <div className={styles.paggination}>
            {Object.values(SubOptionsType).map((type) => {
                const subOption: ISubOptions = subOptions(t, type);
                return (
                    <AccessibleButton
                        tag="div"
                        key={subOption.pageNo}
                        data-test={`emb-page-${subOption.pageNo}-btn`}
                        className={classes({
                            [styles.page]: true,
                            [styles.active]: type === viewData.subType,
                        })}
                        action={() => true}
                        onClick={() => {
                            embroideryPage.save();
                            embroideryPage.replaceSubOption(currentPageData, subOption);
                        }}
                    >
                        <span className={styles.title}>{subOption.pageNo}</span>
                    </AccessibleButton>
                );
            })}
        </div>
    );
};
