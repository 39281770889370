import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IStylePreviewProps {
    title: string;
    handleAction: () => void;
}

export const StylePreview = ({
    title,
    handleAction,
    children,
}: React.PropsWithChildren<IStylePreviewProps>) => {
    const t = usePhraseTranslater();
    if (!children) return null;
    return (
        <div
            className={classes({
                [styles.stylePreview]: true,
            })}
        >
            <div className={styles.title}>{t(title)}</div>
            {children}
            <div className={styles.actionWrapper}>
                <AccessibleButton
                    tag="div"
                    className={classes({ [styles.actionButton]: true })}
                    action={handleAction}
                >
                    <i className="fas fa-pencil-alt" />
                </AccessibleButton>
            </div>
        </div>
    );
};
