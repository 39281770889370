import React, { useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const LogoPreview = ({
    selected,
    handleChange,
}) => {
    const t = usePhraseTranslater();
    useEffect(() => {
        if (selected && selected.logoNumber) {
            handleChange({
                value: selected.logoNumber,
                path: selected.src,
                stitchCount: selected.stitchCount,
            });
        }
    }, [selected]);
    if (!selected || !selected.logoNumber) return null;
    return (
        <div className={styles.logoSelectorPreviewContainer}>
            <div className={styles.logoSelectorPreviewHeader}>
                <span className={styles.logoSelectorPreviewTitle}>{t(selected.title || '')}</span>
                <span className={styles.logoSelectorPreviewSubTitle}>{t('Design #%1', selected.logoNumber)}</span>
            </div>
            <div className={styles.logoSelectorPreviewBody}>
                <img src={selected.src} alt={selected.title || ''} />
            </div>
        </div>
    );
};
