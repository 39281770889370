import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IErrorMessageProps {
    show: boolean;
    message: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = ({ show, message }) => (
    <div
        className={classes(styles.invalidText, {
            [styles.visible]: show,
        })}
    >
        {message}
    </div>
);
