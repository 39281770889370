import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ButtonProps {
    faIcon: string;
    text: string;
    action: () => void;
    validate: Function | Boolean;
}

export const LeftActionButton = ({
    faIcon,
    text,
    action,
    validate,
}: ButtonProps) => (
    <>
        <div className={styles.downloadButtonWrapper}>
            <AccessibleButton
                tag="div"
                action={action}
                className={classes(styles.downloadButton, {
                    [styles.disabled]: !validate,
                })}
            >
                <i className={faIcon} />
            </AccessibleButton>
            <AccessibleButton
                tag="div"
                action={action}
                className={classes(styles.downloadButtonText, {
                    [styles.disabled]: !validate,
                })}
            >
                {text}
            </AccessibleButton>
        </div>
    </>
);
