import React from 'react';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const PricePreview = ({ prices }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.price}>
            <dl className={styles.priceList}>
                {prices.map(price => (
                    <React.Fragment key={price.label}>
                        <dd>
                            <Price
                                className={classes({
                                    [styles.priceText]: true,
                                    [styles.priceExcluded]: !price.isCountInTotal,
                                })}
                                price={price.price}
                            />
                        </dd>
                        <dt>
                            {price.description && '*'}
                            {t(price.description)}
                        </dt>
                    </React.Fragment>
                ))}
            </dl>
        </div>
    );
};
