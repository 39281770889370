import React from 'react';
import { createPortal } from 'react-dom';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';
import { LogoSignInPopupBox } from './logo-sign-in-popup-form';
import styles from './style.css';

export const LogoSignInBox = () => {
    const t = usePhraseTranslater();

    return (
        <ModalSystemConsumer>
            {({ open, setOpen }) => (
                <>
                    <div className={styles.logoNotLoggedInMessageWrapper}>
                        <span className={styles.logoNotLoggedInMessage}>
                            {t('To select logos linked to your account')}
                        </span>
                    </div>
                    <div className={styles.logoSignInButtonWrapper}>
                        <button
                            type="button"
                            className={styles.logoSignInButton}
                            onClick={() => setOpen(true)}
                        >
                            {t('Sign In')}
                        </button>
                    </div>
                    {open && createPortal(
                        <LogoSignInPopupBox
                            handleClose={() => setOpen(false)}
                        />,
                        getEmbeddedEmbConfiguratorElement(),
                    )}
                </>
            )}
        </ModalSystemConsumer>
    );
};
