import React from 'react';
import { SameFlags } from './same-flags';
import { LeftRightFlags } from './left-right-flags';
import { FlagsWithCheckbox } from './flags-with-checkbox';
import { IEmbroideryParams } from '../embroidery';
import { FlagRoles } from '../flag-roles';
import { getViewData } from '../../util';

export const Configure: React.FC<IEmbroideryParams> = (
    {
        embroiderer,
        embroideryPage,
    },
) => {
    const { leftRightFlagsEnabled } = embroideryPage;
    const viewData = getViewData(embroiderer);
    if (leftRightFlagsEnabled) {
        const flagsEdit = embroideryPage.flag1.split('/');
        if (flagsEdit.length > 1) {
            const [left, right] = flagsEdit;
            embroideryPage.setFlag(left, FlagRoles.flag1);
            embroideryPage.setFlag(right, FlagRoles.flag2);
        }
        if (embroideryPage.checkbox === true || (embroideryPage.flag1 && embroideryPage.flag2)) {
            return (
                <LeftRightFlags
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                    viewData={viewData}
                />
            );
        }
        return (
            <FlagsWithCheckbox
                embroiderer={embroiderer}
                embroideryPage={embroideryPage}
            />
        );
    }
    return (
        <SameFlags
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        />
    );
};
