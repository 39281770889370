import React from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { LogoDataObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { LogoPreview } from './logo-preview';
import styles from './style.css';

const viewType = {
    upload: true,
    uploaded: true,
};

interface ILogoUpload {
    logo: LogoDataObject;
    embroideryPage: any; // TODO: CWM2-7095: add embroideryPage type
}

export const LogoUpload = ({
    logo, embroideryPage,
}: ILogoUpload) => (
    <React.Fragment>
        {
            logo.logo && viewType[logo.logo.type] && (
                <LogoPreview
                    src={logo.logo.imageUrl}
                />
            )
        }
        {
            !logo.logo && (
                <>
                    <div className={styles.loadingImageContainer + (embroideryPage.uploading ? '' : ` ${styles.hide}`)}>
                        <LoadingImage parentClass={styles.logoUploadLoading} />
                    </div>
                </>
            )
        }
    </React.Fragment>
);
