export const clear = state => ({
    ...state,
    lines: [],
    color: '',
    font: '',
    placement: '',
    enabled: false,
});

export const clearText = state => ({
    ...state,
    lines: [''],
});

export const clearStyle = state => ({
    ...state,
    color: '',
    font: '',
    placement: '',
});
