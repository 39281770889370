import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { TextSwatches } from './text-swatches';
import styles from './style.css';

interface IFaceSelectorProps {
    embroiderer: EmbroidererProps;
}

export const FaceSelector: React.FC<IFaceSelectorProps> = ({ embroiderer }) => (
    <TextSwatches
        items={embroiderer.faces as any[]}
        labelSelector={x => x.name}
        isSelected={x => embroiderer.face === x}
        keySelector={x => x.name}
        handleChangeSelector={x => embroiderer.setFace(x.name)}
        className={styles.faceSelector}
        enableNavigation
    />
);
