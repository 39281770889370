import React from 'react';

export class State extends React.Component<any> {
    render() {
        const { children, data } = this.props;
        if (typeof children !== 'function') return null;
        return children({
            embroideryPage: {
                error: data,
                label: 'Submission Error',
            },
        });
    }
}
