import React from 'react';
import styles from './style.css';

export const Title: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => (
    <div className={styles.title}>
        {children}
    </div>
);
