import React from 'react';
import { types } from 'ui/component/embroidery-configurator/pages/types';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { UploadButton } from 'ui/component/embroidery-configurator/components/labeled-button';
import { Divider } from 'ui/component/embroidery-configurator/components/divider';
import { Price } from 'ui/component/embroidery-configurator/components/price';
import { showUpload } from 'ui/component/embroidery-configurator/pages/logos/util/show-upload';
import { LogoPreview } from '../logo-preview';
import { LogoAddLine } from '../logo-add-line';
import styles from './style.css';

export const LogoContainer = ({ embroiderer, parentEmbroideryPage, option }) => {
    const t = usePhraseTranslater();
    const logoFormatConstraint = 'jpg, png, tiff, eps, dst';
    const currentPageData = extractState(embroiderer, 'logos');
    const LogoComponents = types.logos;
    const action = {
        onFileAction: (i) => {
            // TODO: CWM2-7091: we want to save data via below function but its not working as expected.
            // embroideryPage.useOnFile(logoNum)();
            parentEmbroideryPage.openSubOption(currentPageData, { subType: 'onFile', logoNumber: Number(i) });
        },
        uploadAction: (ev, i) => {
            // TODO: CWM2-7091: we want to save data via below function but its not working as expected.
            // embroideryPage.upload(logoNum, ev.target.files[0]);
            parentEmbroideryPage.openSubOption(currentPageData, {
                subType: 'upload',
                file: ev.target.files[0],
                logoNumber: Number(i),
            });
        },
    };

    return (
        <div className={styles.logoContainer}>
            <LogoComponents.State embroiderer={embroiderer} option={option}>
                {({ embroideryPage }) => {
                    // populates logo buttons after add/remove any logo
                    if (embroideryPage.canAddLogo &&
                        embroideryPage.isLogoSelected(embroideryPage.logos.length - 1)
                    ) {
                        embroideryPage.addLogo();
                    }
                    return (
                        <>
                            <div className={classes(styles.actionButtonWrapper, styles.actionButtonCompactWrapper)}>
                                {embroideryPage.logos.map((logo, i) => (
                                    /* eslint-disable react/no-array-index-key */
                                    <React.Fragment key={i}>
                                        {logo.logo ? (
                                            <>
                                                <LogoPreview
                                                    logoNumber={i}
                                                    logo={logo}
                                                    price={embroideryPage.logoPrices[i]}
                                                    allowRemove={!embroideryPage.isDefaultAndLocked(logo)}
                                                    embroiderer={embroiderer}
                                                />
                                                <Divider />
                                            </>
                                        ) : null}
                                        {!logo.logo && logo.isRemoved && (
                                            <>
                                                <LogoAddLine
                                                    LogoNumber={i}
                                                    handleAction={() => embroideryPage.addLogoLine(i)}
                                                />
                                                <Divider />
                                            </>
                                        )}
                                        {!logo.logo && !logo.isRemoved && (
                                            <>
                                                <div className={styles.actionTitle}>
                                                    {t('logo %1', i + 1)}
                                                </div>
                                                <Price
                                                    price={embroideryPage.onFilePrice}
                                                    className={styles.price}
                                                />
                                                <AccessibleButton
                                                    tag="div"
                                                    data-test="logo-onfile-button"
                                                    data-test-new="emb-logo-onfile-btn"
                                                    className={classes({
                                                        [styles.actionButton]: true,
                                                        [styles.logoAction]: true,
                                                    })}
                                                    action={() => action.onFileAction(i)}
                                                >
                                                    <span className={styles.title}>{t('Logo On File')}</span>
                                                    <span className={styles.rightIcon}>
                                                        <i className="fas fa-folder" />
                                                    </span>
                                                </AccessibleButton>
                                                {
                                                    embroideryPage.logos.length - 1 !== i &&
                                                    !showUpload(i + 1, embroiderer) &&
                                                    <Divider />
                                                }
                                            </>
                                        )}
                                        {!logo.logo && !logo.isRemoved && showUpload(i + 1, embroiderer) && (
                                            <>
                                                <Price
                                                    price={embroideryPage.digitizationPrice}
                                                    className={styles.price}
                                                />
                                                <UploadButton
                                                    dataTest="logo-upload"
                                                    dataTestNew="emb-logo-upload-btn"
                                                    handleChange={(ev) => {
                                                        action.uploadAction(ev, i);
                                                    }}
                                                    className={classes({
                                                        [styles.actionButton]: true,
                                                        [styles.logoAction]: true,
                                                    })}
                                                >
                                                    <span className={styles.title}>{t('Upload Logo')}</span>
                                                    <span
                                                        className={classes({
                                                            [styles.rightIcon]: true,
                                                            [styles.spinner]: Boolean(embroideryPage.uploading),
                                                        })}
                                                    >
                                                        {embroideryPage.uploading ? (
                                                            <i className="fas fa-spinner" />
                                                        ) : (
                                                            <i className="fa-solid fa-arrow-up-from-bracket" />
                                                        )}
                                                    </span>
                                                </UploadButton>
                                                { embroideryPage.logos.length - 1 !== i && <Divider />}
                                            </>
                                        )}
                                    </React.Fragment>
                                    /* eslint-enable */
                                ))}
                            </div>
                            {embroideryPage.maxLogos && (
                                <div className={styles.footerNotes}>
                                    <div className={styles.note}>
                                        {t('%1 Logos Max - %2', Number(embroideryPage.maxLogos), logoFormatConstraint)}
                                    </div>
                                </div>
                            )}
                        </>
                    );
                }}
            </LogoComponents.State>
        </div>
    );
};
