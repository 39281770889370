import React, { CSSProperties, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

import ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface =
    Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;

interface ITextInputProps {
    value: string;
    placeholder: string;
    handleChange: (value: string) => void;
    className: string;
    handleEnter?: () => void;
    dataTest?: string;
    style?: CSSProperties;
    hasError?: boolean;
    rulePerLine?: ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;
    isEmbroideryLockdown?: boolean;
    isSectionInvalid?: boolean;
}

export const TextInput: React.FC<ITextInputProps> = ({
    className,
    value,
    placeholder,
    dataTest,
    handleChange,
    handleEnter,
    style,
    hasError,
    rulePerLine,
    isSectionInvalid,
}) => {
    const t = usePhraseTranslater();
    const isRequired = Boolean(rulePerLine?.is_required);
    const isRequiredInvalid = isRequired && isSectionInvalid && (!value || !value.length);
    const isLocked = Boolean(rulePerLine?.locked);
    const characterLimit = rulePerLine?.character_limit ?? 20;
    const [maxCharsHit, setMaxCharsHit] = useState(false);
    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (characterLimit && ev.target.value.length >= characterLimit) {
            setMaxCharsHit(true);
            return;
        }
        if (characterLimit && ev.target.value.length < characterLimit) {
            setMaxCharsHit(false);
        }
        handleChange(ev.target.value);
    };
    return (
        <div className={className}>
            {rulePerLine && rulePerLine.label && (
                <span className={classes(styles.textLabel, {
                    [styles.requiredLine]: isRequired,
                })}
                >
                    {rulePerLine.label}
                </span>
            )}

            <input
                value={value}
                className={classes(styles.textInput, {
                    [styles.error]: hasError || isRequiredInvalid,
                })}
                placeholder={placeholder}
                data-test={dataTest}
                onChange={handleInputChange}
                onKeyPress={(ev) => {
                    if (ev.charCode === 13 && handleEnter) {
                        handleEnter();
                    }
                }}
                style={style}
                type="text"
                disabled={isLocked}
            />

            <span className={styles.errorText}>
                {maxCharsHit && t('Max character limit of %1 reached', characterLimit)}
            </span>
        </div>
    );
};
