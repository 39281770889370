import styles from './style.css';

export const layers = (state, face) => {
    if (!state.flag) return [];

    const retval = [];
    state.flagPlacements.forEach((x) => {
        if (x.face === face) {
            retval.push({ src: x.image, className: styles.flags });
        }
    });

    return retval;
};
