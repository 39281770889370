import React from 'react';
import { PageHeader } from './page-header';
import { PageBody } from './page-body';
import styles from './style.css';

interface PageProps {
    embroiderer: any;
    embroideryPage: any;
    style?: any;
}

export const Page = ({
    embroiderer,
    embroideryPage,
    style,
    children,
}: React.PropsWithChildren<PageProps>) => (
    <div className={styles.page} style={style}>
        <PageHeader handleBackClick={embroiderer.popView} type={embroideryPage.type} />
        <PageBody>
            {children}
        </PageBody>
    </div>
);
