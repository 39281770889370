import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { baseName } from 'ui/util/product-images/sort-images';
import styles from './style.css';

export const isImageFile = (src: string): boolean => {
    const srcSplit = src.split('.');
    const nonImgExtensions = ['eps', 'dst'];
    return !nonImgExtensions.includes(srcSplit[srcSplit.length - 1].toLowerCase());
};

interface ILogoPreviewProps {
    src: string;
}

export const LogoPreview = ({ src }: ILogoPreviewProps) => (
    <div className={styles.uploadImgWrap}>
        {isImageFile(src) ? (
            <img src={src} className={styles.uploadImg} alt="" />
        ) : (
            <span className={classes(styles.uploadImg, 'fa fa-file')}>
                <span className={styles.fileName}>
                    {baseName(src)}
                </span>
            </span>
        )}
    </div>
);
