import React, { useEffect, useRef, useState } from 'react';
import { FaceSelector } from 'ui/component/embroidery-configurator/components/text-swatches/face-selector';
import { useDetectSticky } from 'chefworks-theme/src/hooks/useDetectSticky';
import { classes } from '@silkpwa/module/util/classes';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { Images } from './images';
import { LiquidPixel } from './liquid-pixel';
import { EmbroidererProps } from '../../add-embroidery-state';
import { LeftActions, RightActions } from '../preview-actions';
import styles from './style.css';

interface IPreviewProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any;
    account: AccountState;
}

export const Preview: React.FC<IPreviewProps> = ({ embroiderer, embroideryPage, account }) => {
    const wrapRef = useRef<HTMLDivElement>(null);
    const topRef = useRef<HTMLDivElement>(null);
    const middleRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const [isSticky] = useDetectSticky(wrapRef);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const updateHeight = () => {
        if (!wrapRef.current || !middleRef.current || !bottomRef.current || !topRef.current) return;

        requestAnimationFrame(() => {
            if (wrapRef.current.clientWidth < 500) {
                const mobilePreviewHeight = middleRef.current.clientWidth + 56;
                middleRef.current.style.height = `${mobilePreviewHeight}px`;
                return;
            }
            middleRef.current.style.height = '70vh';
        });
    };

    useEffect(() => {
        updateHeight();
    }, [wrapRef.current]);


    return (
        <div
            className={classes({
                [styles.preview]: true,
                [styles.sticky]: isSticky,
                [styles.shadow]: isSticky,
                [styles.fullScreen]: isFullScreen,
            })}
            ref={wrapRef}
        >
            <LeftActions account={account} embroiderer={embroiderer} isSticky />
            {isSticky && <RightActions setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen} />}
            {embroiderer.liquidPixel.enabled ? (
                <LiquidPixel
                    baseUrl={embroiderer.liquidPixel.baseUrl}
                    productId={embroiderer.liquidPixel.liquidPixelSku || embroiderer.configurableSku}
                    productColor={embroiderer.productColor}
                    face={embroiderer.face ? embroiderer.face.name : ''}
                    options={embroiderer.options}
                    embroideryPage={embroideryPage}
                    forwardRef={(ref) => {
                        middleRef.current = ref;
                    }}
                >
                    <div ref={bottomRef} className={styles.previewBottom}>
                        <FaceSelector embroiderer={embroiderer} />
                    </div>
                </LiquidPixel>
            ) : (
                <Images
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                    forwardRef={(ref) => {
                        middleRef.current = ref;
                    }}
                >
                    <div ref={bottomRef} className={styles.previewBottom}>
                        <FaceSelector embroiderer={embroiderer} />
                    </div>
                </Images>
            )}
        </div>
    );
};
