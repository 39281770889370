import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

const defaultTestFun = _ => null;

export const ColorSwatches = ({
    renderSwatch,
    dataTestFun,
    items,
    keySelector,
    isSelected,
    handleChangeSelector,
}) => {
    const getTest = dataTestFun || defaultTestFun;

    return (
        <ul className={styles.colorListWrap}>
            {items.map(item => (
                <AccessibleButton
                    tag="li"
                    key={keySelector(item)}
                    action={handleChangeSelector(item)}
                    data-test={getTest(item)}
                    className={classes(styles.colorItemWrap, {
                        [styles.enabled]: isSelected(item),
                    })}
                >
                    <div className={styles.colorImgWrap}>
                        {renderSwatch(item)}
                    </div>
                </AccessibleButton>
            ))}
        </ul>
    );
};
