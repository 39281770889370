import React from 'react';
import { clear } from './clear';
import { isValid } from './is-valid';
import { extractState, nextPage } from '../util';
import { price } from './price';

export class State extends React.Component<any, any> {
    constructor(props) {
        super(props);

        const { embroiderer, data } = this.props;
        if (data.sku) {
            const [firstOption] = embroiderer.options.filter(
                (x: { type: string; sku: string }) => x.type === 'alteration-piping' && x.sku === data.sku,
            );
            this.state = firstOption;
        } else {
            this.state = extractState(embroiderer, 'alteration-piping');
        }

        this.setText = this.setText.bind(this);
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
    }

    setText(text) {
        this.setState({ text });
        this.save();
    }

    save() {
        const { embroiderer } = this.props;
        if (isValid(this.state)) {
            embroiderer.saveOption(this.state);
        }
    }

    clear() {
        this.setState(clear);
    }

    render() {
        const { children, embroiderer } = this.props;
        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                setText: this.setText,
                save: this.save,
                clear: this.clear,
                price: price(
                    this.state,
                    embroiderer.embroideryState,
                    embroiderer.quantity,
                ),
                canSave: isValid(this.state),
                nextPage: nextPage(embroiderer, 'alteration-piping'),
            },
        });
    }
}
