import React, { useEffect, useState } from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { Checkbox, CheckboxEventHandler } from './checkbox-flags';
import { Flags } from './flags';
import { FlagPositionRoles, FlagRoles } from '../flag-roles';
import { IEmbroideryParams } from '../embroidery';

interface ILeftRightFlagsProps extends IEmbroideryParams {
    viewData: {subType: Exclude<FlagRoles, FlagRoles.flag>};
}

export const LeftRightFlags: React.FC<ILeftRightFlagsProps> = (
    {
        embroiderer,
        embroideryPage,
        viewData,
    },
) => {
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange: CheckboxEventHandler = (event) => {
        embroideryPage.setCheckBoxValue(event.target.checked)();
        setIsChecked(event.target.checked);
        embroideryPage.clear();
    };

    useEffect(() => {
        embroideryPage.setFlagPosition(
            embroideryPage.flagPlacements,
            embroideryPage.flag1Position || FlagPositionRoles.flag1Position,
            FlagRoles.flag1,
        );
    }, []);
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </Section>
            <Flags
                embroiderer={embroiderer}
                embroideryPage={embroideryPage}
                type={viewData.subType}
            />
        </OptionPage>
    );
};
