import { connect } from '@silkpwa/redux';

export const connectProductEntity = connect({
    using: ['ecommerceProductEntity'],
    mapStateToProps: product => state => ({
        getProduct: product.selectors.getProduct.bind(null, state),
    }),
    mapDispatchToProps: product => dispatch => ({
        loadProductData: productId => dispatch(product.actions.loadProduct(productId)),
    }),
});
