import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IRemoveButtonProps {
    className?: string;
    dataTest?: string;
    handleRemove: any;
}

export const RemoveButton: React.FC<IRemoveButtonProps> = ({ className, handleRemove, dataTest }) => (
    <div className={classes({ [className]: Boolean(className), [styles.removeButton]: true })}>
        <AccessibleButton
            tag="span"
            action={handleRemove}
        >
            <i className="fa-solid fa-xmark" data-test={dataTest} />
        </AccessibleButton>
    </div>
);
