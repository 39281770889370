import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';

/**
 * LineNumber > 0
 * @param lineNumber
 * @param embroiderer
 */
export const showUpload = (lineNumber: number, embroiderer: EmbroidererProps): boolean => {
    // eslint-disable-next-line no-restricted-syntax
    for (const allowedLogoNumbersPerLine of embroiderer.allowedLogoNumbers) {
        if (allowedLogoNumbersPerLine.line_number === lineNumber) {
            return allowedLogoNumbersPerLine.upload_allowed;
        }
    }
    return true;
};
