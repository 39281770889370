import React, { useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

interface ExpandableListProps {
    title: string;
    data: React.JSX.Element[];
    styles: { [key: string]: string };
    length?: number;
}
const ExpandableList: React.FC<ExpandableListProps> = ({
    title,
    data,
    styles,
    length = 4,
}) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(prev => !prev);
    const t = usePhraseTranslater();
    const itemsToShow = showMore ? data : data.slice(0, length);

    if (data.length === 0) return null;

    return (
        <>
            <div className={styles.actionTitle}>
                {title}
                <span className={styles.customizationItemContCnt}>
                    {` ( ${data.length} ${data.length > 1 ? t('items') : t('item')} )`}
                </span>
            </div>
            <div className={styles.altPrintCnt}>
                {itemsToShow.map(section => section)}
            </div>
            {data.length > length && (
                <div className={styles.addMoreCnt}>
                    <AccessibleButton
                        className={styles.addMoreLinkCnt}
                        action={toggleShowMore}
                        tag="a"
                    >
                        {showMore ? t('Show Less') : t('Show More')}
                    </AccessibleButton>
                </div>
            )}
        </>
    );
};

export default ExpandableList;
