import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { NextButton } from 'ui/component/embroidery-configurator/pages/flags/configure/action-buttons';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { flagsPreview } from 'ui/component/embroidery-configurator/pages/flags/util/flags-preview';
import { flagsData, FlagDataByType } from 'ui/component/embroidery-configurator/pages/flags/util/flags-data';
import { ColorSwatches, ImageSwatch } from 'ui/component/embroidery-configurator/components/color-swatches';
import { Divider } from 'ui/component/embroidery-configurator/components/divider';
import { Description } from './description';
import { FlagRoles } from '../flag-roles';
import { IEmbroideryFlagParams } from '../embroidery';
import styles from './style.css';

export const Flags: React.FC<IEmbroideryFlagParams> = (
    {
        embroiderer,
        embroideryPage,
        type,
    },
) => {
    const t = usePhraseTranslater();
    const flagData: FlagDataByType = flagsData(embroideryPage, t, type);
    const { subtitle } = flagData;
    const actionNext = ({ type, embroiderer, embroideryPage }: IEmbroideryFlagParams) => {
        if (type === FlagRoles.flag || type === FlagRoles.flag2) {
            embroideryPage.save();
            embroiderer.popView();
        } else {
            embroiderer.replaceView('flags', { subType: FlagRoles.flag2 });
        }
    };
    return (
        <Section>
            <div className={styles.note}>
                {t('One flag will be placed on each collar')}
            </div>
            <Divider />
            {subtitle ? (
                <div className={styles.subTitle}>
                    {t(subtitle)}
                </div>
            ) : (
                <div className={styles.extraSpace} />
            )}
            <Description content={flagData.description} />
            <ColorSwatches
                items={embroideryPage.flagOptions}
                isSelected={f => flagData.value === f.backendValue}
                keySelector={f => f.backendValue}
                renderSwatch={item => (
                    <ImageSwatch src={item.frontendValue} />
                )}
                dataTestFun={item => `flag-${item.backendValue}`}
                handleChangeSelector={f => () => {
                    embroiderer.setFace('Front')();
                    embroideryPage.setFlag(f.backendValue, type);
                }}
            />
            <div className={styles.flagPreview}>
                {flagsPreview(embroideryPage, type)}
            </div>
            <NextButton
                embroideryPage={embroideryPage}
                viewData={{ subType: type }}
                onClick={() => {
                    actionNext({ type, embroiderer, embroideryPage });
                }}
            />
        </Section>
    );
};
