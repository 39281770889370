import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { LeftActionButton } from 'ui/component/embroidery-configurator/pages/start/configure/left-action-button';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { SaveEcPopupModal } from './save-ec-popup-modal';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface ISaveEcButtonProps {
    account: AccountState;
    embroiderer: EmbroidererProps;
}

export const SubmitEcButton = ({ account, embroiderer }: ISaveEcButtonProps) => {
    const t = usePhraseTranslater();
    const queryString = useContainerHook<() => any>('useQueryString');
    const text = (queryString.emb_flag) ? t('Update My Products') : t('My Products');
    const config: SilkRestappDataConfigInfoInterface = useConfig();
    const ismyProductsModuleEnabled = config.extension_attributes?.myproducts_module_enabled;
    const ecButton = 'POPUP';
    const canSave = !embroiderer.cannotSave;
    if (!(embroiderer.liquidPixel.enabled && ismyProductsModuleEnabled && account.isLoggedIn)) return null;
    return (
        <SaveEcPopupModal ec={ecButton} embroiderer={embroiderer} text={text}>
            {show => (
                <>
                    <LeftActionButton
                        faIcon="fa-solid fa-floppy-disk"
                        text={text}
                        action={show}
                        validate={canSave}
                    />
                </>
            )}
        </SaveEcPopupModal>
    );
};
