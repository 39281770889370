import { FlagRoles } from '../flag-roles';

export const flagsPreview = (
    options: {
        flag: string;
        flag1: string;
        flag2: string;
        flagOptions: any[];
    },
    type: 'flag' | 'flag1' | 'flag2',
): string => {
    let flagValue: string;
    switch (type) {
        case FlagRoles.flag:
            flagValue = options.flag || '';
            break;
        case FlagRoles.flag1:
            flagValue = options.flag1 || '';
            break;
        case FlagRoles.flag2:
            flagValue = options.flag2 || '';
            break;
        default:
            flagValue = '';
            break;
    }

    if (flagValue) {
        const [option] = options.flagOptions.filter(x => (
            x.backendValue === flagValue
        ));
        return `${option.countryName || option.backendValue}`;
    }

    return '';
};
