export interface IPriceItem {
    label: string;
    description: string;
    price: number;
    isCountInTotal: boolean;
}

const uploadPrice = (state, quantity): IPriceItem => ({
    label: 'Embroidery Fee',
    description: 'Logo',
    price: state.uploadPrice * quantity,
    isCountInTotal: true,
});

const onFilePrice = (state, quantity): IPriceItem => ({
    label: 'Embroidery Fee',
    description: 'Logo',
    price: state.onFilePrice * quantity,
    isCountInTotal: true,
});

const digitizationFeePrice = (state): IPriceItem => ({
    label: 'Digitization Fee',
    description: 'New Logo Digitization Fee',
    price: state.digitizationPrice,
    isCountInTotal: true,
});

const appliedDigitizationFeePrice = (): IPriceItem => ({
    label: 'Applied Digitization Fee',
    description: 'Digitization Fee Already Applied',
    price: 0,
    isCountInTotal: false,
});

const emptyLogoPrice = (): IPriceItem => ({
    label: 'Empty Logo Line',
    description: 'Empty Logo Line',
    price: 0,
    isCountInTotal: false,
});

const digitizationPrice = (inputLogoSet, state, hash, quantity): IPriceItem[] => {
    const logoSet = inputLogoSet;
    if (logoSet[hash]) {
        return [uploadPrice(state, quantity), appliedDigitizationFeePrice()];
    }

    logoSet[hash] = true;
    return [uploadPrice(state, quantity), digitizationFeePrice(state)];
};

const logoPrice = (state, logoSet, logo, quantity): IPriceItem[] | [] => {
    if (!logo.logo) return [emptyLogoPrice()];

    if (logo.logo.type === 'on-file') {
        return [onFilePrice(state, quantity)];
    }
    if (logo.logo.type === 'uploaded') {
        return digitizationPrice(logoSet, state, logo.logo.hash, quantity);
    }
    if (logo.logo.type === 'upload') {
        // API is returning hash (as secret_key) so we are directly checking for same file
        return digitizationPrice(logoSet, state, logo.logo.uploadResult.secret_key, quantity);
    }

    return [];
};

const logoPrices = (state, embroideryState, quantity): IPriceItem[] | [] => {
    const logoSet = { ...embroideryState.logoSet };

    const prices = state.logos.map(x => logoPrice(state, logoSet, x, quantity));

    return prices;
};

export { logoPrices };


const sum = (xs, v = 0) => xs.reduce((acc, x) => acc + x, v);

export const price = (state, embroideryState, quantity) => {
    const logoPriceValues = logoPrices(state, embroideryState, quantity)
        .map(prices => sum(
            prices.map((p) => {
                if (p.isCountInTotal) {
                    return p.price;
                }
                return 0;
            }),
        ));
    return sum(logoPriceValues, state.basePrice);
};
