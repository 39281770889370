import React from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const Configure = ({ embroiderer, embroideryPage }) => {
    const t = usePhraseTranslater();
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            <Section>
                <SectionTitle>Submission Error</SectionTitle>
                <p className={styles.errWrap}>
                    {t('An error ocurred while submitting your product:')}
                </p>
                <p className={styles.errWrap}>
                    <span className={styles.errContent}>{embroideryPage.error.message}</span>
                </p>
                <p className={styles.errWrap}>
                    {t('Please correct the above error and try submitting again.')}
                </p>
            </Section>
        </OptionPage>
    );
};
