import { IImageInfo, LogoObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';

export const extractState = (embroiderer, optionType) => (
    embroiderer.options.filter(x => x.type === optionType)[0]
);

export const nextPage = (embroiderer, optionType) => {
    let index = 0;
    while (embroiderer.options[index].type !== optionType) index += 1;
    index += 1;

    if (embroiderer.options[index]) return embroiderer.options[index];

    return undefined;
};

export const selectedValue = (options, option, transform = (x => x.frontendValue)) => options
    .filter(x => x.backendValue === option)
    .map(transform)[0];

export const getViewData = (embroiderer: { viewStack: any }) => {
    const { viewStack } = embroiderer;
    if (viewStack.length <= 1) return null;
    const { viewData } = viewStack.filter((_, i) => i === viewStack.length - 1)[0];
    return viewData;
};

export const getImageInfo = (logo: LogoObject): IImageInfo => {
    let url = '';
    let altText = '';
    const placeHolderUrl = 'https://www.chefworks.com/media/wysiwyg/Placeholder_Logo_Emb.png';
    if (logo.type === 'on-file') {
        url = logo.valueObj?.path || placeHolderUrl;
        altText = logo.valueObj?.value || 'Placeholder logo';
    } else if (logo.type === 'upload') {
        url = logo.imageUrl || placeHolderUrl;
        altText = logo.uploadResult?.title || 'Uploaded logo';
    } else if (logo.type === 'uploaded') {
        url = logo.imageUrl || placeHolderUrl;
        altText = logo.sourceValue?.title || 'Uploaded logo';
    }

    return {
        url,
        altText,
    };
};
