import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';

/**
 * LineNumber > 0
 * @param lineNumber
 * @param embroiderer
 */
export const getAllowedLogosForLine = (lineNumber: number, embroiderer: EmbroidererProps): string[] => {
    // eslint-disable-next-line no-restricted-syntax
    for (const allowedLogoNumbersPerLine of embroiderer.allowedLogoNumbers) {
        if (allowedLogoNumbersPerLine.line_number === lineNumber) {
            return allowedLogoNumbersPerLine.logo_numbers_array;
        }
    }

    return [];
};
