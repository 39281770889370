import React from 'react';
import { enhanceState } from '@silkpwa/module/util/react-state-enhancer';
import {
    LogoDataObject,
    LogoValueObject,
    ILogosOption,
} from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import isEqual from 'lodash/isEqual';
import { clear } from './clear';
import { isValid } from './is-valid';
import { extractState, nextPage } from '../util';
import { logoPrices } from './price';

export class State extends React.Component<any, ILogosOption> {
    private setIn;

    private updateIn;

    private removeIn;

    private isLoggedIn;

    constructor(props) {
        super(props);

        const { embroiderer } = this.props;
        const currentPageData = extractState(embroiderer, 'logos');

        this.state = {
            ...currentPageData,
            logos: this.initLogos(currentPageData),
            uploading: false,
            error: null,
        };

        this.upload = this.upload.bind(this);
        this.setSide = this.setSide.bind(this);
        this.setPlacement = this.setPlacement.bind(this);
        this.setLiquidPixelPlacement = this.setLiquidPixelPlacement.bind(this);
        this.addLogo = this.addLogo.bind(this);
        this.addLogoLine = this.addLogoLine.bind(this);
        this.removeLogo = this.removeLogo.bind(this);
        this.isLogoSelected = this.isLogoSelected.bind(this);
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
        this.clearLogo = this.clearLogo.bind(this);
        this.useOnFile = this.useOnFile.bind(this);
        this.setLogoOnFile = this.setLogoOnFile.bind(this);
        this.isDefaultAndLocked = this.isDefaultAndLocked.bind(this);

        this.componentWillReceiveProps = this.performUpdates.bind(this);
    }

    componentDidUpdate(): void {
        this.reloadPricesWhenLoggedInOrOut();
    }

    // eslint-disable-next-line class-methods-use-this
    get emptyLogo() {
        return {
            logo: null,
            side: '',
            placement: '',
        };
    }

    get canAddLogo() {
        const { logos, maxLogos } = this.state;
        return logos.length < maxLogos;
    }

    get canRemoveLogo() {
        const { logos, minLogos } = this.state;
        return logos.length > minLogos;
    }

    setLogoOnFile(logoNum, valueObj: LogoValueObject) {
        const { onFileAllowed } = this.state;
        if (!onFileAllowed(valueObj.value)) return;

        this.setIn(['logos', logoNum, 'logo', 'valueObj'], valueObj);
    }

    setSide(logoNum, side) {
        return () => this.setIn(['logos', logoNum, 'side'], side);
    }

    setPlacement(logoNum, placement) {
        return () => this.setIn(['logos', logoNum, 'placement'], placement);
    }

    setLiquidPixelPlacement(logoNum, placement) {
        return () => this.setIn(['logos', logoNum, 'liquidPixelPlacement'], placement);
    }

    isDefaultAndLocked(logo: LogoDataObject): boolean {
        if (!logo || !logo.logo || !logo.logo.valueObj || !logo.logo.valueObj.value) {
            return false;
        }

        const { embroiderer } = this.props;

        return embroiderer.defaultLogo &&
            embroiderer.defaultLogo.lock &&
            embroiderer.defaultLogo.number === logo.logo.valueObj.value;
    }

    reloadPricesWhenLoggedInOrOut(): void {
        const { embroiderer } = this.props;
        if (this.isLoggedIn !== embroiderer.account.isLoggedIn) {
            const currentPageData = extractState(embroiderer, 'logos');
            this.setState({
                digitizationPrice: currentPageData.digitizationPrice,
                onFilePrice: currentPageData.onFilePrice,
                uploadPrice: currentPageData.uploadPrice,
            });

            this.isLoggedIn = embroiderer.account.isLoggedIn;
        }
    }

    initLogos(data) {
        const logos = [...data.logos];

        while (logos.length < data.minLogos) logos.push(this.emptyLogo);

        return logos;
    }

    useOnFile(logoNum) {
        return () => this.setIn(['logos', logoNum, 'logo'], {
            type: 'on-file',
            valueObj: {
                value: '',
                path: '',
                stitchCount: '',
            },
        });
    }

    async upload(logoNum, file) {
        try {
            this.setState({
                uploading: true,
            });

            /* eslint no-underscore-dangle: 0 */
            const { _sourceMapping: { virtualProductId } } = this.state;
            const { embroiderer: { embroideryRepository } } = this.props;
            const uploadResult = await embroideryRepository.upload(virtualProductId, file);

            this.setIn(['logos', logoNum, 'logo'], {
                type: 'upload',
                imageUrl: uploadResult.url,
                uploadResult,
                file,
            });
        } catch (e) {
            this.setState({
                uploading: false,
                error: new Error(e.message ?? e),
            });
        }

        this.setState({
            uploading: false,
        });
    }

    isLogoSelected(logoNum) {
        const { logos } = this.state;
        const { logo } = logos.filter((_, i) => i === logoNum)[0];
        if (!logo) return false;
        if (logo.type === 'upload' && logo.file.name) return true;
        if (logo.type === 'uploaded' && logo.imageUrl) return true;
        return !!(logo.valueObj?.value);
    }

    clearLogo(logoNum) {
        return () => {
            this.setIn(['logos', logoNum, 'logo'], null);
        };
    }

    addLogo() {
        return this.updateIn(['logos'], logos => [...logos, this.emptyLogo]);
    }

    addLogoLine(logoNum) {
        return this.setIn(['logos', logoNum], this.emptyLogo);
    }

    removeLogo(logoNum) {
        const { logos } = this.state;
        const isNeededToAddAnEmptyLogo = Boolean(!logos || logos.length <= 1);
        this.removeIn(['logos', logoNum], () => this.save(false));
        if (isNeededToAddAnEmptyLogo) {
            this.addLogo();
        }
    }

    save(isValidate = true) {
        const { embroiderer } = this.props;
        if (!isValidate || isValid(this.state)) {
            embroiderer.saveOption(this.state);
        }
    }

    clear() {
        const state = clear(this.state);
        this.setState({
            ...state,
            logos: this.initLogos(state),
        });
    }

    performUpdates(newProps) {
        const { option } = newProps;
        // update state when pre-populated logos are available
        if (option && option.logos.length && !isEqual(this.state, option)) {
            this.setState(s => ({
                ...s,
                ...option,
            }));
        }
    }

    render() {
        const { children, embroiderer } = this.props;

        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                canRemoveLogo: this.canRemoveLogo,
                canAddLogo: this.canAddLogo,
                addLogo: this.addLogo,
                removeLogo: this.removeLogo,
                setPlacement: this.setPlacement,
                setLiquidPixelPlacement: this.setLiquidPixelPlacement,
                setSide: this.setSide,
                upload: this.upload,
                save: this.save,
                clear: this.clear,
                addLogoLine: this.addLogoLine,
                canSave: isValid(this.state),
                nextPage: nextPage(embroiderer, 'logos'),
                isLogoSelected: this.isLogoSelected,
                clearLogo: this.clearLogo,
                useOnFile: this.useOnFile,
                setLogoOnFile: this.setLogoOnFile,
                defaultLogo: embroiderer.defaultLogo,
                isDefaultAndLocked: this.isDefaultAndLocked,
                logoPrices: logoPrices(
                    this.state,
                    embroiderer.embroideryState,
                    embroiderer.quantity,
                ),
            },
        });
    }
}

enhanceState(State);
