import React from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { TextSwatches } from 'ui/component/embroidery-configurator/components/text-swatches';
import { SubsectionTitle } from 'ui/component/embroidery-configurator/components/subsection-title';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    IPlacementOption,
    IPlacementOptionPerLine,
    LogoDataObject,
} from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { Divider } from 'ui/component/embroidery-configurator/components/divider';
import { NextButton } from 'ui/component/embroidery-configurator/pages/logos/configure/action-buttons';
import {
    getPlacementsSelectedByOtherLogos,
    getAllPlacementsSelectedByOtherLogos,
} from 'ui/component/embroidery-configurator/pages/logos/util/get-placements-selected-by-other-logos';
import { LogoActions } from './logo-actions';
import styles from './style.css';

interface LogoProps {
    logo: LogoDataObject;
    logoNum: number;
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: I'm not sure how to add an interface for this - will need to come back to it
}

export const Logo = ({
    logo,
    logoNum,
    embroiderer,
    embroideryPage,
}: LogoProps) => {
    const t = usePhraseTranslater();
    const isDefaultAndLocked = embroideryPage.isDefaultAndLocked(logo);

    const getPlacementOptionsForLine = (lineNumber: number): IPlacementOptionPerLine | null => {
        // eslint-disable-next-line no-restricted-syntax
        for (const lineOption of embroideryPage.placementOptionsPerLine) {
            if (lineOption.lineNumber === lineNumber) {
                return lineOption;
            }
        }

        return null;
    };

    const linePlacementOptions = getPlacementOptionsForLine(logoNum + 1);
    const linePlacementOptionsValArray = linePlacementOptions ? linePlacementOptions.placements.map(
        p => p.backendValue,
    ) : [];

    const placementOptions =
        embroideryPage.placementOptions.filter(
            (placementOption: IPlacementOption) => (!isDefaultAndLocked ||
                    (isDefaultAndLocked && placementOption.backendValue ===
                        embroideryPage.defaultLogo.default_placement)) &&
                (linePlacementOptionsValArray.length <= 0 ||
                    linePlacementOptionsValArray.includes(placementOption.backendValue)),
        );

    const isPlacementSelected = (logo: LogoDataObject) => {
        if (!logo) return false;
        return logo.placement?.length > 0;
    };

    return (
        <>
            <Section>
                <LogoActions
                    logoNum={logoNum}
                    logo={logo}
                    embroiderer={embroiderer}
                    embroideryPage={embroideryPage}
                />
            </Section>
            {embroideryPage.placementOptions.length > 0 && embroideryPage.isLogoSelected(logoNum) && (
                <>
                    <Divider />
                    <Section>
                        <div className={styles.positionWrapper}>
                            <SubsectionTitle>{t('Select Position')}</SubsectionTitle>
                            <TextSwatches
                                items={placementOptions as any[]}
                                labelSelector={x => x.frontendValue}
                                isSelected={x => logo.placement === x.frontendValue}
                                shouldDisable={x => getPlacementsSelectedByOtherLogos(embroideryPage.logos, logoNum)
                                    .includes(x.frontendValue)
                                }
                                disabledByIndex={(x) => {
                                    const index = getAllPlacementsSelectedByOtherLogos(embroideryPage.logos)
                                        .indexOf(x.frontendValue);
                                    if (index !== logoNum) return index;
                                    return -1;
                                }}
                                keySelector={x => x.frontendValue}
                                dataTestFun={item => `placement-${item.frontendValue}`}
                                handleChangeSelector={x => () => {
                                    embroiderer.setFace(x.face)();
                                    embroideryPage.setPlacement(logoNum, x.frontendValue)();
                                    embroideryPage.setLiquidPixelPlacement(logoNum, x.backendValue)();
                                }}
                            />
                            {isPlacementSelected(logo) && (
                                <NextButton
                                    onClick={() => {
                                        embroideryPage.save(false);
                                        embroiderer.popView();
                                    }}
                                />
                            )}
                        </div>
                    </Section>
                </>
            )}
        </>
    );
};
