import { selectedValue } from '../util';

export const layers = (state, face) => state.logos.map((x, i) => {
    const placement = selectedValue(state.placementOptions, x.placement, y => y);

    if (!placement) return [];
    if (placement.face !== face) return [];

    const { image } = placement;

    // hack for v1
    if (i === 1) return image && image.replace('LogoA', 'LogoB');

    return image;
}).filter(x => x);
