import React, { useEffect } from 'react';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { getViewData } from 'ui/component/embroidery-configurator/pages/util';
import { SubOptionsType } from 'ui/component/embroidery-configurator/pages/logos/util/sub-options';
import { Logo } from './logo';
import styles from './style.css';

export const Configure = ({ embroiderer, embroideryPage }) => {
    const viewData = getViewData(embroiderer);
    useEffect(() => {
        // TODO: CWM2-7095: remove this use effect, try to make useOnFile working from logo-container.tsx
        if (viewData.subType === SubOptionsType.onFile) {
            embroideryPage.useOnFile(viewData.logoNumber)();
        } else if (viewData.subType === SubOptionsType.upload && viewData.file) {
            embroideryPage.upload(viewData.logoNumber, viewData.file);
        }
    }, []);
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            {embroideryPage.error?.message ? (
                <div className={styles.errorMessage}>{embroideryPage.error?.message}</div>
            ) : (
                <div>
                    {embroideryPage.logos.map((logo, i) => {
                        if (viewData.logoNumber !== i) return null;
                        return (
                            /* eslint-disable react/no-array-index-key */
                            <Logo
                                key={i}
                                logoNum={i}
                                logo={logo}
                                embroiderer={embroiderer}
                                embroideryPage={embroideryPage}
                            />
                            /* eslint-enable */
                        );
                    })}
                </div>
            )}
        </OptionPage>
    );
};
