import React from 'react';
import { clear } from './clear';
import { isValid } from './is-valid';
import { extractState, nextPage } from '../util';

export class State extends React.Component<any, any> {
    constructor(props) {
        super(props);

        const { embroiderer } = this.props;
        this.state = extractState(embroiderer, 'special-instructions');

        this.setText = this.setText.bind(this);
        this.save = this.save.bind(this);
        this.clear = this.clear.bind(this);
    }

    setText(text) {
        this.setState({ text });
        this.save();
    }

    save() {
        const { embroiderer } = this.props;

        if (isValid(this.state)) {
            embroiderer.saveOption(this.state);
        }
    }

    clear() {
        this.setState(clear);
    }

    render() {
        const { children, embroiderer } = this.props;

        if (typeof children !== 'function') return null;

        return children({
            embroideryPage: {
                ...this.state,
                setText: this.setText,
                save: this.save,
                clear: this.clear,
                canSave: isValid(this.state),
                nextPage: nextPage(embroiderer, 'special-instructions'),
            },
        });
    }
}
