import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const CancelButtonEc = ({ action, className }) => {
    const t = usePhraseTranslater();
    return (
        <AccessibleButton
            tag="div"
            data-test="cancel-embroidery-ec"
            action={action}
            className={classes(styles.cancelButtonEcPopup, className)}
        >
            {t('CANCEL')}
        </AccessibleButton>

    );
};
