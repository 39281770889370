import React from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { SectionTitle } from 'ui/component/embroidery-configurator/components/section-title';
import { TextSwatches } from 'ui/component/embroidery-configurator/components/text-swatches';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const FontSection = ({ embroideryPage }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <SectionTitle>{t('Font Style')}</SectionTitle>
            <Section>
                <TextSwatches
                    items={embroideryPage.fontOptions as any[]}
                    isSelected={f => f.frontendValue === embroideryPage.font}
                    labelSelector={f => f.frontendValue}
                    keySelector={f => f.frontendValue}
                    dataTestFun={item => `font-${item.frontendValue}`}
                    fontSelector={f => f.frontendValue}
                    handleChangeSelector={f => embroideryPage.setFont(f.frontendValue)}
                />
            </Section>
        </>
    );
};
