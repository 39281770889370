import {
    LogoObject,
    LogoDataObject,
    ILogosOption,
} from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';

const typeValidators = {
    'on-file': (logo: LogoObject) => logo.valueObj && logo.valueObj.value.length > 0,
    upload: (logo: LogoObject) => logo.file,
    uploaded: (logo: LogoObject) => logo.imageUrl,
};

const isValidLogoInput = (logo: LogoObject) => {
    if (!logo) return false;

    return typeValidators[logo.type](logo);
};

const isValidSide = (
    logo: LogoDataObject,
    state: ILogosOption,
) => logo.side?.length > 0 || state.sideOptions.length === 0;

const isValidPlacement = (logo: LogoDataObject, state: ILogosOption) => (
    logo.placement?.length > 0 ||
    state.placementOptions.length === 0
);

const isValidLogo = (logo: LogoDataObject, state: ILogosOption) => (
    isValidLogoInput(logo.logo) &&
    isValidSide(logo, state) &&
    isValidPlacement(logo, state)
);

/**
 * Now onwards logos can be empty.
 * - in order to calculate correct prices.
 * - For lockdown, we need to check exact number of valid logos
 * - For non lockdown, valid logos can be between min nad max Logos
 *
 * @param state
 * @param embroiderer
 */
export const isValid = (state: ILogosOption, embroiderer: EmbroidererProps | undefined = undefined) => {
    const validCount = embroiderer && embroiderer.isEmbroideryLockdown
        ? state.logos.length === state.maxLogos
        : state.logos.length >= state.minLogos && state.logos.length <= state.maxLogos;

    const validLogosCount = state.logos.filter(x => isValidLogo(x, state)).length;
    const validLogos = embroiderer && embroiderer.isEmbroideryLockdown
        ? validLogosCount === state.maxLogos
        : validLogosCount >= state.minLogos && validLogosCount <= state.maxLogos;

    return validCount && validLogos;
};
