import { LogoDataObject } from '../../../api/embroidery-interfaces';

/**
 * This will return all the selected placement except the one user is currently uploading/selecting on
 * @param logos
 * @param logoNum
 */
export const getPlacementsSelectedByOtherLogos = (logos: LogoDataObject[], logoNum: number): string[] => {
    if (logos.length === 0) return [];
    return logos.map((logo, index) => {
        if (logoNum !== index) return logo.placement;
        return null;
    }).filter(placement => placement !== null) as string[];
};

/**
 * This will return all the placement along with removed logo placement (empty)
 * @param logos
 */
export const getAllPlacementsSelectedByOtherLogos = (logos: LogoDataObject[]): string[] => {
    if (logos.length === 0) return [];
    return logos.map(logo => logo.placement) as string[];
};
