import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface IAddItemProps {
    handleClick: (
        (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) =>
        void
    );
    dataTest?: string;
    children: React.ReactNode;
}

export const AddItem: React.FC<IAddItemProps> = ({
    dataTest,
    handleClick,
    children,
}) => (
    <AccessibleButton
        tag="div"
        data-test={dataTest}
        className={styles.addLine}
        action={handleClick}
    >
        <div className={styles.text}>{children}</div>
        <i className="fa fa-plus" />
    </AccessibleButton>
);
