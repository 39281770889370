import React from 'react';
import { Section } from 'ui/component/embroidery-configurator/components/section';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { subOptions, SubOptionsType } from 'ui/component/embroidery-configurator/pages/text/util/sub-options';
import { FontSection } from './font-section';
import { ColorSection } from './color-section';
import { PlacementSection } from './placement-section';
import { Pagination } from './pagination';
import { NextButton, SaveButton } from './action-buttons';
import { ErrorMessage } from './action-buttons/error-message';

const validSubType = (subType: string): boolean => {
    const validType = ['color', 'font', 'placement'];
    return validType.includes(subType);
};

export const Configure = ({ embroideryPage, embroiderer }) => {
    const t = usePhraseTranslater();
    const currentPageData = extractState(embroiderer, 'text');
    const { viewStack } = embroiderer;
    if (viewStack.length <= 1) return null;
    const { viewData } = viewStack.filter((_, i) => i === viewStack.length - 1)[0];
    if (!validSubType(viewData.subType)) return null;
    const { nextSubOption } = subOptions(t, viewData.subType);
    return (
        <OptionPage
            embroiderer={embroiderer}
            embroideryPage={embroideryPage}
        >
            {
                viewData.subType === SubOptionsType.color && (
                    <ColorSection
                        embroiderer={embroiderer}
                        embroideryPage={embroideryPage}
                    />
                )
            }
            {
                viewData.subType === SubOptionsType.font && (
                    <FontSection
                        embroideryPage={embroideryPage}
                    />
                )
            }
            {
                viewData.subType === SubOptionsType.placement && (
                    <PlacementSection
                        embroiderer={embroiderer}
                        embroideryPage={embroideryPage}
                    />
                )
            }
            {nextSubOption && (
                <Section>
                    <NextButton
                        embroideryPage={embroideryPage}
                        viewData={viewData}
                        onClick={() => {
                            embroideryPage.save();
                            embroideryPage.replaceSubOption(currentPageData, nextSubOption);
                        }}
                    />
                </Section>
            )}
            {!nextSubOption && (
                <Section>
                    <SaveButton embroiderer={embroiderer} embroideryPage={embroideryPage} />
                </Section>
            )}
            <ErrorMessage embroideryPage={embroideryPage} viewData={viewData} />
            <Section>
                <Pagination embroiderer={embroiderer} embroideryPage={embroideryPage} viewData={viewData} />
            </Section>
        </OptionPage>
    );
};
