import React from 'react';
import { connectWishlist } from '@silkpwa/module/react-component/connect-wishlist';
import { classes } from '@silkpwa/module/util/classes';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import styles from './style.css';

interface IAddWishlistProps {
    wishlistHasProduct: (productId: number) => boolean;
    toggleWishlist: (productId: number) => void;
    productId: number;
}

const AddWishlist: React.FC<IAddWishlistProps> = ({
    wishlistHasProduct,
    toggleWishlist,
    productId,
}) => {
    if (isPunchoutStoreMode()) return null;
    return (
        <div>
            <button
                className={classes(styles.addWishButton, {
                    [styles.inWishlist]: wishlistHasProduct(productId),
                })}
                onClick={() => toggleWishlist(productId)}
                data-test="btn-add-to-wishlist"
                type="button"
            />
        </div>
    );
};

export const WishlistButton = connectWishlist(AddWishlist);
