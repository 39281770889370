import React from 'react';
import { ILogoPreviewProps } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { PricePreview } from 'ui/component/embroidery-configurator/pages/logos/configure/price-preview';
import { RemoveButton } from 'ui/component/embroidery-configurator/components/remove-button';
import { IConfirmation } from 'ui/component/embroidery-configurator/pages/confirmation/configure';
import { ConfirmationType } from 'ui/component/embroidery-configurator/pages/confirmation/confirmation-type';
import { getImageInfo } from 'ui/component/embroidery-configurator/pages/util';
import styles from './style.css';

export const LogoPreview = ({
    logoNumber,
    logo,
    price,
    allowRemove,
    embroiderer,
}: React.PropsWithChildren<ILogoPreviewProps>) => {
    const t = usePhraseTranslater();
    const imageData = getImageInfo(logo.logo);

    const action = {
        confirmAction: () => {
            const viewData: IConfirmation = {
                title: t('Are you sure you want to delete your logo?'),
                confirmationType: ConfirmationType.YesNo,
                data: {
                    logoNumber,
                    image: imageData,
                },
            };
            embroiderer.pushView('confirmation', viewData);
        },
    };

    return (
        <div
            className={classes({
                [styles.stylePreview]: true,
            })}
        >
            <div className={styles.logoPreview}>
                <img src={imageData.url} alt={t(imageData.altText)} />
            </div>
            <div className={styles.logoDetail}>
                <div className={styles.info}>
                    <div className={styles.title}>{t('logo %1', logoNumber + 1)}</div>
                    <div className={styles.position}>{t('%1', logo.placement)}</div>
                    <div className={styles.price}>
                        <PricePreview prices={price} />
                    </div>
                </div>
                <div className={styles.action}>
                    {allowRemove && (
                        <RemoveButton handleRemove={action.confirmAction} className={styles.actionButton} />
                    )}
                </div>
            </div>
        </div>
    );
};
