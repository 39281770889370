import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const ColorSwatch = ({ color, border }) => (
    <span
        className={classes(styles.colorImg, styles.spanContainer)}
        style={{
            backgroundColor: color,
            border,
        }}
    />
);
