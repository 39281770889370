import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface ILogoAddLineProps {
    LogoNumber: number;
    handleAction: () => void;
}

export const LogoAddLine: React.FC<ILogoAddLineProps> = ({ LogoNumber, handleAction }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.addLogoLine}>
            <div className={styles.addLogoLinetitle}>
                {t('logo %1', LogoNumber + 1)}
            </div>
            <div>
                <AccessibleButton
                    tag="div"
                    data-test="emb-logo-add-logo-btn"
                    className={classes({ [styles.actionLink]: true })}
                    action={handleAction}
                >
                    {t('Add Logo')}
                </AccessibleButton>
            </div>
        </div>
    );
};
